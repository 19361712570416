import React, { useState, useRef } from 'react';

import { Button } from '@armis/armis-ui-library';
import { InputAdornment } from '@mui/material';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import {
    EMAIL_SEND_CONFIRM,
    FORGOT_PASSWORD,
    LOG_IN,
    NEXT,
    PASSWORD_PLACEHOLDER,
    REQUIRED,
    USERNAME_PLACEHOLDER,
    USERNAME_REQUIRED,
    WRONG_CRENDENTIALS
} from 'src/constants/LabelText';
import { displayErrorMessage } from 'src/helpers/utility';
import {
    FormContainer,
    InputContainer,
    ButtonContainer,
    MsgContainer,
    StyledTextBox
} from 'src/pages/containers/Login/Login.style';
import { BasicAuthProps } from 'src/pages/containers/Login/Login.types';
import {
    login,
    forgotPassword,
    getSSOId,
    loginWithSSO
} from 'src/services/api.service';
import { setUser } from 'src/store/slices/userSlice';
import { ErrorResponse } from 'src/types/APIResponseTypes';

export const BasicAuth = ({ onClickLogin }: BasicAuthProps) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [submitBtnText, setSubmitBtnText] = useState(NEXT);

    const ref = useRef(null);

    const dispatch = useDispatch();

    const setErrorWithMsg = (msg: string) => {
        setSuccess(false);
        setError(true);
        setMessage(msg);
    };

    const showPasswordField = () => {
        setShowPasswordInput(true);
        setSubmitBtnText(LOG_IN);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            !username.trim().length ||
            (showPasswordInput && !password.trim().length)
        ) {
            setErrorWithMsg(REQUIRED);
            return;
        }

        if (!showPasswordInput) {
            getSSOId({
                username
            })
                .then(({ data }) => {
                    if (data.value) {
                        window.location.href = loginWithSSO(data.value);
                    } else {
                        showPasswordField();
                    }
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    if (err.response) {
                        if (err.response.status === 400) {
                            showPasswordField();
                            return;
                        }
                    }
                    displayErrorMessage(err);
                });
            return;
        }

        setIsLoading(true);

        login({
            username,
            password
        })
            .then(res => {
                setIsLoading(false);
                dispatch(setUser(res.data));
                onClickLogin();
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setSuccess(false);
                setError(true);
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status === 400) {
                        setMessage(WRONG_CRENDENTIALS);
                    } else {
                        setMessage(err.response?.data?.message || '');
                    }
                } else {
                    setMessage(err.message);
                }
            });
    };

    const usernameChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setError(false);
        setUsername(e.target.value);
    };

    const passwordChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setError(false);
        setPassword(e.target.value);
    };

    const forgotPasswordClickHandler = () => {
        if (username.trim().length === 0) {
            setErrorWithMsg(USERNAME_REQUIRED);
            return;
        }

        setError(false);

        forgotPassword({
            username
        })
            .then(() => {
                setSuccess(true);
                setMessage(EMAIL_SEND_CONFIRM);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setSuccess(false);
                setError(true);
                if (err.response) {
                    setMessage(err.response?.data?.errors?.username || '');
                } else {
                    setMessage(err.message);
                }
            });
    };

    return (
        <FormContainer onSubmit={handleSubmit}>
            <div className="input-field">
                <span className="label">
                    {username.length > 0 && 'Username or email'}
                </span>
                <InputContainer>
                    <StyledTextBox
                        error={error}
                        fullWidth
                        id="username-input-box"
                        onChange={e => usernameChangeHandler(e)}
                        placeholder={USERNAME_PLACEHOLDER}
                        size="small"
                        value={username}
                        variant="filled"
                    />
                </InputContainer>
                {showPasswordInput && (
                    <div style={{ marginTop: '25px' }}>
                        <span className="label">
                            {password.length > 0 && 'Password'}
                        </span>
                        <InputContainer>
                            <StyledTextBox
                                error={error}
                                fullWidth
                                id="standard-input-with-link"
                                onChange={e => passwordChangeHandler(e)}
                                placeholder={PASSWORD_PLACEHOLDER}
                                size="small"
                                type="password"
                                value={password}
                                variant="filled"
                            />
                        </InputContainer>
                        <InputAdornment
                            onClick={forgotPasswordClickHandler}
                            position="end"
                            variant="standard"
                        >
                            <span
                                style={{
                                    color: 'white',
                                    fontSize: '11px',
                                    cursor: 'pointer',
                                    fontFamily: 'Proxima Nova W08 Thn Reg'
                                }}
                            >
                                {FORGOT_PASSWORD}
                            </span>
                        </InputAdornment>
                    </div>
                )}
                <ButtonContainer>
                    <Button
                        ref={ref}
                        disabled={isLoading}
                        fullWidth
                        style={{
                            backgroundColor: '#2ABAFF',
                            height: '40px',
                            borderRadius: '4px'
                        }}
                        type="submit"
                        variant="contained"
                    >
                        {submitBtnText}
                    </Button>
                </ButtonContainer>
                {(error || success) && (
                    <MsgContainer variant={error ? 'error' : 'success'}>
                        {message}
                    </MsgContainer>
                )}
            </div>
        </FormContainer>
    );
};
