import React, { useEffect, useState } from 'react';

import { Button, Select, TextBox, Upload } from '@armis/armis-ui-library';
import { InputAdornment, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { IoIosClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import { OIDC_SP_MAPPING, SAML_SP_MAPPING } from 'src/constants/APIResponse';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    API_USER_INFORMATION_URL,
    API_USER_INFORMATION_URL_PLACEHOLDER,
    ATTRIBUTE_BINDING,
    ATTRIBUTE_ENTITY,
    ATTRIBUTE_LOCATION,
    AUTHORIZATION_BASE_URL,
    AUTHORIZATION_BASE_URL_PLACEHOLDER,
    CHOOSE_METADATA_FORMAT,
    CLIENT_EMAIL_SCOPE,
    CLIENT_EMAIL_SCOPE_PLACEHOLDER,
    CLIENT_ID,
    CLIENT_ID_PLACEHOLDER,
    CLIENT_SECRET,
    CLIENT_SECRET_PLACEHOLDER,
    DONE,
    HTTP_POST_LABEL,
    JWT_SET_URL,
    JWT_SET_URL_PLACEHOLDER,
    METADATA_ERROR,
    METADATA_FILE,
    METADATA_URL,
    METADATA_URL_ERROR,
    OIDC_IDP_DESCRIPTION,
    OIDC_IDP_DETAILS,
    OIDC_SP_DESCRIPTION,
    OIDC_SP_DETAILS,
    OPENID_CONNECT,
    QUERY_SELECTOR_CERTIFICATE,
    QUERY_SELECTOR_ENTITYDESCRIPTOR,
    QUERY_SELECTOR_SINGLESIGNONSERVICE,
    SAML,
    SAML_IDP_DESCRIPTION,
    SAML_IDP_DETAILS,
    SAML_SP_DESCRIPTION,
    SAML_SP_DETAILS,
    SAVE,
    LOGIN_MANAGEMENT,
    TOKEN_URL,
    TOKEN_URL_PLACEHOLDER,
    UPLOAD_FILE,
    SSO_LOGIN_METHOD
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    isActionHasPermissions,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { Header } from 'src/pages/components/Header';
import { TextToClipBoard } from 'src/pages/components/TextToClipBoard/TextToClipBoard';
import {
    ChooseMethodBlock,
    OIDCFieldsContainer,
    StyledActionsBox,
    StyledInput
} from 'src/pages/containers/SSO&SAML/SamlSettings.style';
import {
    IDPDetails,
    OIDCSPDetails,
    SamlSettingsProps,
    SAMLSPDetails
} from 'src/pages/containers/SSO&SAML/SamlSettings.types';
import {
    getSPDetails,
    getURLDetails,
    postIDPDetails,
    postOIDCDetails
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';

const metadataDropDownValues = [METADATA_FILE, METADATA_URL];
const authMethodDropDownValues = [SAML, OPENID_CONNECT];
const URLS_FIELDS_KEYS = [
    'authorizationBaseUri',
    'tokenUri',
    'userInfoUri',
    'jwkSetUri'
];
const SAML_SP_FIELDS = ['singleSignOnUrl', 'entityId'];
const OIDC_SP_FIELDS = ['authorizationEndpoint'];
const validLengthObj = {
    clientId: 500,
    clientSecret: 500,
    emailScope: 50,
    authorizationBaseUri: 2048,
    tokenUri: 2048,
    userInfoUri: 2048,
    jwkSetUri: 2048
};
const initialOIDCFieldsData = {
    clientId: '',
    clientSecret: '',
    emailScope: '',
    authorizationBaseUri: '',
    tokenUri: '',
    userInfoUri: '',
    jwkSetUri: ''
};

const SamlSettingsComp = ({ setIsLoading }: SamlSettingsProps) => {
    const [metadataFormat, setMetadataFormat] = useState(0);
    const [authMethod, setAuthMethod] = useState(1);
    const [metadataURL, setMetadataURL] = useState('');
    const [samlSPDetails, setsamlSPDetails] = useState<SAMLSPDetails | {}>({});
    const [oidcSPDetails, setoidcSPDetails] = useState<OIDCSPDetails | {}>({});
    const [idpDetails, setIDPDetails] = useState<IDPDetails | {}>({});
    const [disableSaveButton, setDisableSaveButton] = useState({
        0: true,
        1: true
    });
    const [filename, setFileName] = useState('');
    const [oidcFieldsData, setoidcFieldsData] = useState<{
        clientId: string;
        clientSecret: string;
        emailScope: string;
        authorizationBaseUri: string;
        tokenUri: string;
        userInfoUri: string;
        jwkSetUri: string;
    }>(initialOIDCFieldsData);
    const [errors, setErrors] = useState(new Map());
    const currentUser = useSelector(selectUser);

    const theme = useTheme();

    const makeAnApiCall = async () => {
        try {
            setIsLoading(true, true);
            const {
                data: { ssoType, saml, oidc }
            } = await getSPDetails();

            setsamlSPDetails(saml);
            setoidcSPDetails({
                authorizationEndpoint: oidc.authorizationEndpoint
            });
            if (ssoType === 'SAML') {
                setAuthMethod(0);
            } else if (ssoType === 'OIDC') {
                setoidcFieldsData({
                    clientId: oidc.configuration.clientId,
                    clientSecret: oidc.configuration.clientSecret,
                    emailScope: oidc.configuration.emailScope,
                    authorizationBaseUri:
                        oidc.configuration.authorizationBaseUri,
                    tokenUri: oidc.configuration.tokenUri,
                    userInfoUri: oidc.configuration.userInfoUri,
                    jwkSetUri: oidc.configuration.jwkSetUri
                });
                setAuthMethod(1);
            }
        } catch (err: any) {
            displayErrorMessage(err);
        } finally {
            setIsLoading(false);
        }
    };

    const parseXMLData = (xmlFileData: string) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlFileData, 'text/xml');
        const certificateValue = xml.querySelector(QUERY_SELECTOR_CERTIFICATE)
            ?.childNodes[0]?.nodeValue;
        const entityID = xml
            .querySelector(QUERY_SELECTOR_ENTITYDESCRIPTOR)
            ?.getAttribute(ATTRIBUTE_ENTITY);
        const singleSignOnElements = xml.querySelectorAll(
            QUERY_SELECTOR_SINGLESIGNONSERVICE
        );
        const signOnUrlElement = Array.from(singleSignOnElements).find(
            element =>
                element
                    .getAttribute(ATTRIBUTE_BINDING)
                    ?.includes(HTTP_POST_LABEL)
        );
        const signOnUrl = signOnUrlElement?.getAttribute(ATTRIBUTE_LOCATION);

        return {
            url: signOnUrl,
            entityid: entityID,
            certificate: certificateValue
        };
    };

    const handleCapture = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = async (evt: any) => {
            setIDPDetails(parseXMLData(evt.target.result));
            setFileName(file.name);
            setDisableSaveButton({
                ...disableSaveButton,
                [metadataFormat]: false
            });
        };
    };

    const postApiData = async () => {
        let finalIdpDetails: IDPDetails;
        if (metadataFormat) {
            try {
                const res: AxiosResponse<any> = await getURLDetails(
                    metadataURL
                );
                finalIdpDetails = parseXMLData(res.data) as IDPDetails;
            } catch (err: any) {
                if (err.response) {
                    showToast(METADATA_URL_ERROR, TOAST_TYPE.ERROR, TOAST_ID);
                } else {
                    displayErrorMessage(err);
                }
                return;
            }
        } else {
            finalIdpDetails = idpDetails as IDPDetails;
        }
        const emptyCheck = !Object.values(finalIdpDetails).every(
            element => !!element
        );
        if (emptyCheck) {
            showToast(METADATA_ERROR, TOAST_TYPE.ERROR, TOAST_ID);
            return;
        }
        setIsLoading(true);
        postIDPDetails(finalIdpDetails!)
            .then(() => {
                showToast(DONE, TOAST_TYPE.SUCCESS, TOAST_ID);

                // Reload page first time only when SAML is not configured.
                if (
                    !isActionHasPermissions(currentUser, Resources.roles, [
                        Privileges.edit
                    ])
                ) {
                    window.location.reload();
                }
                setoidcFieldsData(initialOIDCFieldsData);
            })
            .catch((err: any) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                if (metadataFormat) {
                    setMetadataURL('');
                } else {
                    setFileName('');
                }
                setDisableSaveButton({
                    ...disableSaveButton,
                    [metadataFormat]: true
                });
                setIsLoading(false);
            });
    };

    const postOidcApiData = () => {
        setIsLoading(true);
        postOIDCDetails(oidcFieldsData)
            .then(() => {
                showToast(DONE, TOAST_TYPE.SUCCESS, TOAST_ID);
            })
            .catch((err: any) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleValidateFormFields = () => {
        const errorKeys = new Map();

        const isValidUrl = (urlString: string) => {
            try {
                return Boolean(new URL(urlString));
            } catch (e) {
                return false;
            }
        };

        const validLength = (fieldKey: string, value: string) =>
            value.length <=
            validLengthObj[fieldKey as keyof typeof validLengthObj];

        URLS_FIELDS_KEYS.forEach(fieldKey => {
            const value = oidcFieldsData[
                fieldKey as keyof typeof oidcFieldsData
            ] as string;

            if (!isValidUrl(value)) {
                errorKeys.set(fieldKey, 'URL is invalid');
            }
        });

        Object.keys(validLengthObj).forEach(fieldKey => {
            const value = oidcFieldsData[
                fieldKey as keyof typeof oidcFieldsData
            ] as string;
            if (!validLength(fieldKey, value)) {
                errorKeys.set(
                    fieldKey,
                    `Max ${
                        validLengthObj[fieldKey as keyof typeof validLengthObj]
                    } characters are allowed.`
                );
            }
        });

        setErrors(errorKeys);

        return errorKeys.size === 0;
    };

    const saveButtonDisabled = () => {
        let returnValue = false;

        Object.keys(oidcFieldsData).forEach(key => {
            const value = oidcFieldsData[key as keyof typeof oidcFieldsData];

            if (value.length === 0) {
                returnValue = true;
            }
        });

        return returnValue;
    };

    const handleSave = () => {
        if (authMethod === 0) {
            postApiData();
        } else if (handleValidateFormFields()) {
            postOidcApiData();
        }
    };

    useEffect(() => {
        makeAnApiCall();
    }, []);

    useEffect(() => {
        setDisableSaveButton({
            ...disableSaveButton,
            [metadataFormat]: metadataURL.length === 0
        });
    }, [metadataURL]);

    const oidcSingleField = (
        fieldName: string,
        label: string,
        placeholder: string
    ) => (
        <>
            <span>{label}</span>
            <TextBox
                autoComplete="off"
                className="url"
                error={errors.has(fieldName)}
                fullWidth
                helperText={errors.has(fieldName) ? errors.get(fieldName) : ''}
                hiddenLabel
                InputProps={{
                    endAdornment: oidcFieldsData[
                        fieldName as keyof typeof oidcFieldsData
                    ] && (
                        <InputAdornment
                            onClick={() =>
                                setoidcFieldsData(prev => ({
                                    ...prev,
                                    [fieldName]: ''
                                }))
                            }
                            position="end"
                            variant="outlined"
                        >
                            <IoIosClose />
                        </InputAdornment>
                    ),
                    style: {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? '#2B2732'
                                : '#ffffff',
                        fontSize: '13px !important'
                    }
                }}
                onChange={e =>
                    setoidcFieldsData(prev => ({
                        ...prev,
                        [fieldName]: e.target.value
                    }))
                }
                placeholder={placeholder}
                size="small"
                value={oidcFieldsData[fieldName as keyof typeof oidcFieldsData]}
                variant="outlined"
            />
        </>
    );

    const samlSettings = () => (
        <>
            <div className="sp-details">
                <div className="title-wrapper">
                    <div
                        className="title"
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                    >
                        {SAML_SP_DETAILS}
                    </div>
                </div>
                <div className="description">{SAML_SP_DESCRIPTION}</div>
                {SAML_SP_FIELDS.map((key, index) => (
                    <div
                        key={index}
                        className="control code-block theme-default horizontal"
                    >
                        <span className="title">
                            {
                                SAML_SP_MAPPING[
                                    key as keyof typeof SAML_SP_MAPPING
                                ]
                            }
                        </span>
                        <TextToClipBoard
                            text={
                                samlSPDetails[key as keyof typeof samlSPDetails]
                            }
                        />
                    </div>
                ))}
            </div>
            <div className="idp-details">
                <div className="title-wrapper">
                    <div
                        className="title"
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                    >
                        {SAML_IDP_DETAILS}
                    </div>
                </div>
                <div className="description">{SAML_IDP_DESCRIPTION}</div>
                <div className="metadata-select-wrapper">
                    <span className="metadata-select-text">
                        {CHOOSE_METADATA_FORMAT}{' '}
                    </span>
                    <Select
                        MenuProps={{
                            sx: { zIndex: 10002 }
                        }}
                        onChange={e =>
                            setMetadataFormat(e.target.value as number)
                        }
                        value={metadataFormat}
                    >
                        {metadataDropDownValues.map((element, index) => (
                            <MenuItem
                                key={index}
                                style={{
                                    color:
                                        theme.palette.mode === 'dark'
                                            ? 'white'
                                            : ''
                                }}
                                value={index}
                            >
                                {element}
                            </MenuItem>
                        ))}
                    </Select>
                </div>

                {metadataFormat === 0 ? (
                    <div className="file-wrapper" style={{ marginTop: '5px' }}>
                        <div className="element field">
                            <span className="text">{METADATA_FILE}</span>
                        </div>
                        <span className="file-name">{filename}</span>
                        <label
                            className="control file-input"
                            htmlFor="file-upload"
                        >
                            <div className="input">
                                <div
                                // className="control has-inline-title svg-symbol-button transition theme-3"
                                // style={{ width: '160px' }}
                                >
                                    <input hidden type="file" />
                                    <Button
                                        className="control svg-symbol-button transition theme-5"
                                        color="primary"
                                        component="span"
                                        startIcon={<Upload />}
                                        variant="text"
                                    >
                                        {UPLOAD_FILE}
                                    </Button>
                                </div>
                            </div>
                        </label>
                        <StyledInput
                            accept=".xml"
                            id="file-upload"
                            onChange={handleCapture}
                            type="file"
                        />
                    </div>
                ) : (
                    <div className="url-wrapper">
                        <span className="metadata-url-text">
                            {METADATA_URL}
                        </span>
                        <TextBox
                            autoComplete="off"
                            className="url"
                            fullWidth
                            hiddenLabel
                            InputProps={{
                                endAdornment: metadataURL && (
                                    <InputAdornment
                                        onClick={() => setMetadataURL('')}
                                        position="end"
                                        variant="outlined"
                                    >
                                        <IoIosClose />
                                    </InputAdornment>
                                ),
                                style: {
                                    backgroundColor:
                                        theme.palette.mode === 'dark'
                                            ? '#2B2732'
                                            : '#ffffff'
                                }
                            }}
                            onChange={e => {
                                setMetadataURL(e.target.value);
                            }}
                            size="small"
                            value={metadataURL as string}
                            variant="outlined"
                        />
                    </div>
                )}
            </div>
        </>
    );

    const oidcSettings = () => (
        <>
            <div className="sp-details">
                <div className="title-wrapper">
                    <div
                        className="title"
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                    >
                        {OIDC_SP_DETAILS}
                    </div>
                </div>
                <div className="description">{OIDC_SP_DESCRIPTION}</div>
                {OIDC_SP_FIELDS.map((key, index) => (
                    <div
                        key={index}
                        className="control code-block theme-default horizontal"
                    >
                        <span className="title">
                            {
                                OIDC_SP_MAPPING[
                                    key as keyof typeof OIDC_SP_MAPPING
                                ]
                            }
                        </span>
                        <TextToClipBoard
                            text={
                                oidcSPDetails[key as keyof typeof oidcSPDetails]
                            }
                        />
                    </div>
                ))}
            </div>
            <div className="idp-details">
                <div className="title-wrapper">
                    <div
                        className="title"
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                    >
                        {OIDC_IDP_DETAILS}
                    </div>
                </div>
                <div className="description">{OIDC_IDP_DESCRIPTION}</div>
                <OIDCFieldsContainer>
                    {oidcSingleField(
                        'clientId',
                        CLIENT_ID,
                        CLIENT_ID_PLACEHOLDER
                    )}
                    {oidcSingleField(
                        'clientSecret',
                        CLIENT_SECRET,
                        CLIENT_SECRET_PLACEHOLDER
                    )}
                    {oidcSingleField(
                        'emailScope',
                        CLIENT_EMAIL_SCOPE,
                        CLIENT_EMAIL_SCOPE_PLACEHOLDER
                    )}
                    {oidcSingleField(
                        'authorizationBaseUri',
                        AUTHORIZATION_BASE_URL,
                        AUTHORIZATION_BASE_URL_PLACEHOLDER
                    )}
                    {oidcSingleField(
                        'tokenUri',
                        TOKEN_URL,
                        TOKEN_URL_PLACEHOLDER
                    )}
                    {oidcSingleField(
                        'userInfoUri',
                        API_USER_INFORMATION_URL,
                        API_USER_INFORMATION_URL_PLACEHOLDER
                    )}
                    {oidcSingleField(
                        'jwkSetUri',
                        JWT_SET_URL,
                        JWT_SET_URL_PLACEHOLDER
                    )}
                </OIDCFieldsContainer>
            </div>
        </>
    );

    return (
        <>
            <div style={{ marginBottom: '4px' }}>
                <Header title={LOGIN_MANAGEMENT} />
            </div>
            <div
                className="saml"
                style={{
                    color: theme.palette.mode === 'dark' ? 'white' : '#121212',
                    marginTop: '-30px'
                }}
            >
                <ChooseMethodBlock>
                    <div style={{ marginBottom: '4px' }}>
                        {SSO_LOGIN_METHOD}
                    </div>
                    <Select
                        MenuProps={{
                            sx: { zIndex: 10002 }
                        }}
                        onChange={e => setAuthMethod(e.target.value as number)}
                        value={authMethod}
                    >
                        {authMethodDropDownValues.map((element, index) => (
                            <MenuItem
                                key={index}
                                style={{
                                    color:
                                        theme.palette.mode === 'dark'
                                            ? 'white'
                                            : ''
                                }}
                                value={index}
                            >
                                {element}
                            </MenuItem>
                        ))}
                    </Select>
                </ChooseMethodBlock>
                <div className="saml-settings">
                    {authMethod === 0 ? samlSettings() : oidcSettings()}
                    <StyledActionsBox>
                        <Button
                            disabled={
                                authMethod === 0
                                    ? disableSaveButton[
                                          metadataFormat as keyof typeof disableSaveButton
                                      ]
                                    : saveButtonDisabled()
                            }
                            onClick={handleSave}
                            variant="contained"
                        >
                            {SAVE}
                        </Button>
                    </StyledActionsBox>
                </div>
            </div>
        </>
    );
};

export const SamlSettings = IsLoadingHOC(SamlSettingsComp);
