import React, {
    ChangeEvent,
    MouseEvent,
    useEffect,
    useMemo,
    useState
} from 'react';

import {
    Button,
    MenuIcon,
    TextBox,
    Accordion,
    Select,
    Upload,
    MultiSelect,
    IconButton,
    StyledMenuPaper,
    TagsWithInputBox,
    TableNoDataIcon,
    ArrowDown,
    CrossIcon,
    MenuItemProps,
    constants,
    Tooltip
} from '@armis/armis-ui-library';
import 'react-quill/dist/quill.snow.css';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from '@hello-pangea/dnd';
import {
    Checkbox,
    Grid,
    MenuItem,
    Card,
    CardContent,
    CardMedia,
    Menu,
    useEventCallback,
    PaperProps,
    InputAdornment,
    FormLabel,
    Divider,
    FormHelperText,
    SelectChangeEvent,
    InputProps,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { cloneDeep, debounce } from 'lodash';
import { FaArrowRight } from 'react-icons/fa';
import { IoIosClose } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactQuill from 'react-quill';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    columnchart,
    gaugechart,
    linechart,
    overviewText,
    piechart,
    segmentDivider,
    segmentOverviewTitle,
    segmentTitle,
    singlevaluechart,
    tablechart,
    PageBreak
} from 'src/assets/images';
import {
    GET_REPORT_TEMPLATE,
    RUN_REPORT,
    TOAST_ID
} from 'src/constants/APIConstants';
import { FIELD_TYPE_MAP, TagTypes } from 'src/constants/CommonConstants';
import {
    BENCHMARKS,
    CREATE_TAG_INFO,
    DASHBOARD,
    DASHLET,
    DASHLET_DESCRIPTION,
    DOWNLOAD_JSON,
    GENERATE,
    HIDE_DASHLET,
    LAYOUT,
    LIBRARY,
    LOGO,
    NO_DATA_FOUND,
    ONLY_FILE_ALLOWED,
    OVERVIEW_TEXT,
    PREVIEW,
    RECOMMENDATIONS,
    REPORT,
    REPORT_CONFIGURATION,
    REPORT_CREATED_SUCCESSFULLY,
    REPORT_GENERATION_ERROR,
    REPORT_PREVIEW,
    REPORT_TEMPLATE,
    REPORT_TEMPLATE_UPDATED_SUCCESSFULLY,
    REPORT_TITLE,
    REPORT_UPDATE_SUCCESSFULLY,
    REQUIRED_FIELD,
    ROLE_NAME_ERROR_EMPTY,
    SEGMENT_OVERVIEW_TITLE,
    SEGMENT_TITLE,
    SELECT_BUILT_IN_TEMPLATE,
    SIZE_UNDER_2MB,
    SUCCESSFUL_REPORT_GENERATED,
    TENANT,
    UPLOAD_FILE
} from 'src/constants/LabelText';
import { HISTORY, TEMPLATE_ROUTE } from 'src/constants/RouteConstants';
import {
    TOAST_TYPE,
    displayErrorMessage,
    showToast
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { GenerateReport } from 'src/pages/components/GenerateReport/GenerateReport';
import { GenerateReportResponse } from 'src/pages/components/GenerateReport/GenerateReport.types';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import { ReportPreview } from 'src/pages/components/ReportPreview/ReportPreview';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import {
    createReport,
    getAllReportlets,
    getAllReports,
    getDashlets,
    getReportDetail,
    getRunningReport,
    getTags,
    getTenantDashboards,
    getTenantDashlets,
    getTenants,
    previewReportDownload,
    updateReportDetails
} from 'src/services/api.service';
import { axiosConfig } from 'src/services/axiosInstance';
import { v4 as uuid } from 'uuid';

import {
    Content,
    DragableDiv,
    EllipsisTypography,
    MiddleContainer,
    MiddlePaneContainer,
    PageContainer,
    RightPaneContainer,
    RightPaneContainerTwo,
    RightSideContainer,
    RightSideFieldsContainer,
    StyledDiv,
    StyledField,
    StyledHeader,
    StyledModal,
    StyledQuillDiv,
    StyledTypography,
    VisuallyHiddenInput
} from './CreateReport.style';
import {
    CreateReportProps,
    DashletDataType,
    DroppableSourceAndDestinationType,
    MenuItemDataType,
    PagenumberType,
    ReportDataMenuProp,
    ReportFieldDataContentType,
    ReportFieldErrorType,
    ReportFieldsDataType,
    ReportTitleAndNameType,
    ReportletDataType,
    ReportletElementType,
    TenantDataType
} from './CreateReport.types';

// import { AxiosResponse } from 'axios';
// import { GenerateReportResponse } from 'src/pages/components/GenerateReport/GenerateReport.types';

// import { StyledInput } from '../SSO&SAML/SamlSettings.style';

/**
 * Moves an item from one list to another list.
 */

// @ts-ignore
const move = (source, destination, droppableSource, droppableDestination) => {
    if (!destination) return {};

    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    // @ts-ignore
    result[droppableSource.droppableId] = sourceClone;
    // @ts-ignore
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const ITEMS: ReportletElementType[] = [
    {
        id: 'segment_title',
        type: 'segment_title',
        label: 'Segment Title'
    },
    {
        id: 'segment_overview_title',
        type: 'segment_overview_title',
        label: 'Segment Overview Title'
    },
    {
        id: 'overview_text',
        type: 'overview_text',
        label: 'Overview Text'
    },
    {
        id: 'line_break',
        type: 'line_break',
        label: 'Segment Divider'
    },
    {
        id: 'page_break',
        type: 'page_break',
        label: 'Page Break'
    }
];

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [
            { align: '' },
            { align: 'center' },
            { align: 'right' }
            // { align: 'justify' }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }]
    ],
    clipboard: {
        matchVisual: false
    }
};

const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'align'];

const menuItems: MenuItemDataType[] = [
    { value: 'save_as', label: 'Save As' },
    { value: 'download_json', label: DOWNLOAD_JSON }
];

const CreateReportComponent = ({ setIsLoading }: CreateReportProps) => {
    const theme = useTheme();

    const navigate = useNavigate();

    const { state } = useLocation();

    const [expandedPanel, setExpandedPanel] = useState<string | boolean>(false);
    const [leftPanelExpanded, setleftPanelExpanded] = useState<boolean>(true);

    const [dashletData, setDashletData] = useState<DashletDataType>(
        {} as DashletDataType
    );
    const { reportId } = useParams();

    const [reportletData, setReportletData] = useState<ReportletDataType>(
        {} as ReportletDataType
    );
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');

    const [reportTitleAndName, setReportTitleAndName] =
        useState<ReportTitleAndNameType>({
            title: '',
            name: '',
            tags: ['AMP Managed']
        });
    const [icon, setIcon] = useState<any>();

    const [reportFieldsData, setReportFieldsData] =
        useState<ReportFieldsDataType>({
            reportlet_data: []
        });

    const [tenantData, setTenantData] = useState<TenantDataType[]>([]);
    const [selectedTenantId, setSelectedTenantId] = useState<string>('All');
    const [reportList, setReportList] = useState<MenuItemProps[]>([]);
    const [selectedReports, setSelectedReports] = useState<string[]>([]);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<PagenumberType>({
        dashletPageNumber: 0,
        reportletPageNumber: 0
    });

    const [dashboardList, setDashboardList] = useState<MenuItemProps[]>([]);
    const [selectedDashboards, setSelectedDashboards] = useState<number[]>([]);
    const [dashboardCount, setDashboardCount] =
        useState<string>('All Dashboards');
    const [reportCount, setReportCount] = useState<string>('All Reports');
    const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
    const [tags, setTags] = useState<string[]>([]);
    const [logoPath, setLogoPath] = useState<string>('');
    const [isPreviewModalOpen, setIsPreviewModalOpen] =
        useState<boolean>(false);
    const [isWarningModalOpen, setIsWarningModalOpen] =
        useState<boolean>(false);

    const [generateModalType, setGenerateModalType] = useState<string>('');
    const [previewFileType, setPreviewFileType] = useState<string>('');

    const [reportFieldError, setReportFieldError] =
        useState<ReportFieldErrorType>({
            titleError: false,
            titleHelperText: '',
            templateNameError: false,
            templateHelperText: '',
            existingTemplateError: false,
            existingTemplateHelperText: ''
        });

    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

    const [previewpdfFileObj, setPreviewpdfFileObj] = useState({
        uri: '',
        fileType: '',
        fileName: '',
        previewReportId: ''
    });

    const [previewdocFileObj, setPreviewdocFileObj] = useState({
        uri: '',
        fileType: '',
        fileName: '',
        previewReportId: ''
    });

    const [previewpptFileObj, setPreviewpptFileObj] = useState({
        uri: '',
        fileType: '',
        fileName: '',
        previewReportId: ''
    });

    const [previewjsonFileObj, setPreviewjsonFileObj] = useState({
        uri: '',
        fileType: '',
        fileName: '',
        previewReportId: ''
    });

    const [previewFiles, setPreviewFiles] = useState([]);

    const [reportElementError, setReportElementError] = useState<any>([]);

    const [selectedDashboardIds, setSelectedDashboardIds] = useState<any>({
        previewDragAndDropId: '',
        dragAndDropId: ''
    });

    const borderString = `1px solid ${
        theme.palette.mode === 'dark' ? '#5C5C5C' : '#E8E8E8'
    }`;

    const boxShadowString = `${
        theme.palette.mode === 'dark'
            ? '#756d81 0px 0px 20px 2px'
            : 'rgba(0, 0, 0, 0.35) 0px 0px 20px 2px'
    }`;

    // a little function to help us with reordering the result

    const reorder = (
        list: ReportFieldDataContentType[] | string,
        startIndex: number,
        endIndex: number
    ) => {
        setIsFormDirty(true);

        // @ts-ignore
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const setAllData = async () => {
        await getTenants('', false)
            .then(res => setTenantData(res.data.content))
            .catch(err => {
                displayErrorMessage(err);
            });
    };

    const copyAllDashboards = () => {
        setIsLoading(true);

        getTenantDashlets(
            selectedTenantId,
            0,
            dashletData.totalElements || 10,
            selectedDashboards.join()
        )
            .then(res => {
                const copyData = res.data.content.map((dashlet: any) => ({
                    dragAndDropId: uuid(),
                    previewDragAndDropId: uuid(),
                    isReportlet: true,
                    reportlet: {
                        layout: 'LEFT',
                        recommendations: '',
                        recommendationsCheckbox: false,
                        benchmarkCheckbox: false,
                        benchmark: '',
                        hideSection: false,
                        reportDashlet: {
                            title: dashlet.title || '',
                            dashletDescription: '',
                            dashletJson: dashlet.dashletJson
                        }
                    }
                }));

                const copyReportFieldData = cloneDeep(reportFieldsData);

                copyReportFieldData.reportlet_data = [
                    ...copyReportFieldData.reportlet_data,
                    ...copyData
                ];

                setReportFieldsData(copyReportFieldData);
            })
            .catch(err => {
                displayErrorMessage(err);
            })
            .finally(() => setIsLoading(false));
    };

    const copy = (
        source: ReportletElementType[],
        destination: ReportFieldDataContentType[],
        droppableSource: DroppableSourceAndDestinationType,
        droppableDestination: DroppableSourceAndDestinationType,
        draggableId: string
    ) => {
        setIsFormDirty(true);
        // const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        // const item = sourceClone[droppableSource.index];

        let obj = {};

        if (expandedPanel === 'dashlets') {
            const index = dashletData.content.findIndex(
                dashlet => dashlet.id === draggableId
            );
            obj = {
                dragAndDropId: uuid(),
                previewDragAndDropId: uuid(),
                isReportlet: true,
                reportlet: {
                    layout: 'LEFT',
                    recommendations: '',
                    recommendationsCheckbox: false,
                    benchmarkCheckbox: false,
                    benchmark: '',
                    hideSection: false,
                    reportDashlet: {
                        title: dashletData.content[index].title,
                        dashletDescription: '',
                        dashletJson: dashletData.content[index].dashletJson
                    }
                }
            };
        } else if (expandedPanel === 'reportlets') {
            const index = reportletData.content.findIndex(
                object => object.id === draggableId
            );

            obj = {
                dragAndDropId: uuid(),
                previewDragAndDropId: uuid(),
                isReportlet: true,
                reportlet: {
                    layout: 'LEFT',
                    recommendations:
                        reportletData.content[index].recommendations,
                    recommendationsCheckbox:
                        reportletData.content[index].recommendationsCheckbox,
                    benchmarkCheckbox:
                        reportletData.content[index].benchmarkCheckbox,
                    benchmark: reportletData.content[index].benchmark,
                    reportDashlet: {
                        title: reportletData.content[index].reportDashlet.title,
                        dashletDescription:
                            reportletData.content[index].reportDashlet
                                .dashletDescription,
                        dashletJson:
                            reportletData.content[index].reportDashlet
                                .dashletJson
                    }
                }
            };
        } else if (expandedPanel === 'reportElements') {
            if (
                draggableId === 'segment_title' ||
                draggableId === 'segment_overview_title' ||
                draggableId === 'overview_text'
            ) {
                obj = {
                    isReportlet: false,
                    dragAndDropId: uuid(),
                    previewDragAndDropId: uuid(),
                    reportElement: {
                        elementType: draggableId,
                        value: ''
                    }
                };
            } else if (
                draggableId === 'line_break' ||
                draggableId === 'page_break'
            ) {
                obj = {
                    isReportlet: false,
                    dragAndDropId: uuid(),
                    previewDragAndDropId: uuid(),
                    reportElement: {
                        elementType: draggableId
                    }
                };
            }
        }

        // @ts-ignore
        destClone.splice(droppableDestination.index, 0, {
            ...obj
        });

        return destClone;
    };

    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        switch (source.droppableId) {
            case 'reportlet-preview':
                setReportFieldsData((prevState: any) => ({
                    ...prevState,

                    reportlet_data: reorder(
                        reportFieldsData.reportlet_data,
                        source.index,
                        destination.index
                    )
                }));
                break;
            case destination.droppableId:
                setReportFieldsData(prevState => ({
                    ...prevState,
                    [destination.droppableId]: reorder(
                        // @ts-ignore
                        reportFieldsData[source.droppableId],
                        source.index,
                        destination.index
                    )
                }));
                break;
            case 'dashlets':
            case 'reportlets':
            case 'report-elements':
                setReportFieldsData(prevState => ({
                    ...prevState,

                    reportlet_data: copy(
                        ITEMS,
                        // @ts-ignore
                        prevState.reportlet_data,
                        source,
                        destination,
                        draggableId
                    )
                }));
                break;
            default:
                setReportFieldsData((prevState: any) => ({
                    ...prevState,

                    ...move(
                        prevState[source.droppableId],
                        prevState[destination.droppableId],
                        source,
                        destination
                    )
                }));
        }
    };

    const handleTextChange = (
        e:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<unknown>
            | string
            | MouseEvent<HTMLDivElement, MouseEvent>
            | InputProps,
        item: ReportFieldDataContentType | string,
        key?: string,
        editor?: ReactQuill.UnprivilegedEditor,
        source?: string
    ) => {
        const tempState = { ...reportFieldsData };

        if (source !== 'api') {
            setIsFormDirty(true);
        }

        if (item === 'reportTitle') {
            // @ts-ignore
            if (!e.target.value) {
                setReportFieldError(previous => ({
                    ...previous,
                    titleError: true,
                    titleHelperText: 'This field is required'
                }));

                // @ts-ignore
            } else if (e.target.value.length > 150) {
                setReportFieldError(previous => ({
                    ...previous,
                    titleError: true,
                    titleHelperText:
                        'Max 150 characters are allowed in report title'
                }));
            } else {
                setReportFieldError(previous => ({
                    ...previous,
                    titleError: false,
                    titleHelperText: ''
                }));
            }

            setReportTitleAndName(previous => ({
                ...previous,
                // @ts-ignore
                title: e.target.value
            }));
        } else if (item === 'icon') {
            setLogoPath('');
            // @ts-ignore
            const file = Array.from(e.target.files);

            if (
                // @ts-ignore
                file[0].type === 'image/png' ||
                // @ts-ignore
                file[0].type === 'image/jpeg' ||
                // @ts-ignore
                file[0].type === 'image/svg+xml'
            ) {
                // @ts-ignore
                if (file[0].size / 1024 ** 2 > 2) {
                    showToast(SIZE_UNDER_2MB, TOAST_TYPE.ERROR, TOAST_ID);
                } else {
                    setIcon(file);
                }
            } else {
                showToast(ONLY_FILE_ALLOWED, TOAST_TYPE.ERROR, TOAST_ID);
            }

            // @ts-ignore
            e.target.value = null;
        } else {
            // @ts-ignore
            if (!item.isReportlet) {
                if (
                    e === 'clear' &&
                    key !== 'overview_text' &&
                    (key === 'segment_title' ||
                        key === 'segment_overview_title')
                ) {
                    const index = tempState.reportlet_data.findIndex(
                        object =>
                            // @ts-ignore
                            object.dragAndDropId === item.dragAndDropId
                    );
                    // @ts-ignore
                    tempState.reportlet_data[index].reportElement!.value = '';
                } else {
                    /* eslint-disable */
                    if (key !== 'overview_text') {
                        // @ts-ignore
                        if (e.target.value.length > 150) {
                            setReportElementError((previous: any) => ({
                                ...previous,

                                // @ts-ignore
                                [item.dragAndDropId]: [
                                    {
                                        colName: key,
                                        error: true,
                                        colErrorMessage: `Max 150 characters are allowed in ${
                                            key === 'segment_title'
                                                ? 'segment title'
                                                : 'segment overview title'
                                        }`
                                    }
                                ]
                            }));
                        } else {
                            const oldData = cloneDeep(reportElementError);

                            // @ts-ignore
                            delete oldData[item.dragAndDropId];
                            setReportElementError(oldData);
                        }

                        const index = tempState.reportlet_data.findIndex(
                            object =>
                                // @ts-ignore
                                object.dragAndDropId === item.dragAndDropId
                        );
                        // @ts-ignore
                        tempState.reportlet_data[index].reportElement!.value =
                            // @ts-ignore
                            e.target.value;
                    }
                }

                if (key === 'overview_text') {
                    if (editor?.getText() && editor?.getText().length > 3000) {
                        setReportElementError((previous: any) => ({
                            ...previous,

                            // @ts-ignore
                            [item.dragAndDropId]: [
                                {
                                    colName: 'overview_text',
                                    error: true,
                                    colErrorMessage:
                                        'Max 3000 characters are allowed in overview text'
                                }
                            ]
                        }));
                    } else {
                        const oldData = cloneDeep(reportElementError);

                        // @ts-ignore
                        delete oldData[item.dragAndDropId];
                        setReportElementError(oldData);
                    }

                    const index = tempState.reportlet_data.findIndex(
                        object =>
                            // @ts-ignore
                            object.dragAndDropId === item.dragAndDropId
                    );
                    // @ts-ignore
                    tempState.reportlet_data[index].reportElement!.value = e;
                }
            } else {
                const index = tempState.reportlet_data.findIndex(
                    // @ts-ignore
                    object => object.dragAndDropId === item.dragAndDropId
                );

                if (
                    key === 'dashletDescription' ||
                    key === 'recommendations' ||
                    key === 'benchmark'
                ) {
                    // @ts-ignore
                    const currentId = item.dragAndDropId;

                    if (editor?.getText() && editor?.getText().length > 3000) {
                        if (reportElementError[currentId]) {
                            const oldData = cloneDeep(reportElementError);

                            if (
                                oldData[currentId] &&
                                oldData[currentId].length > 0
                            ) {
                                if (
                                    oldData[currentId].findIndex(
                                        (single: any) => single.colName === key
                                    ) < 0
                                ) {
                                    oldData[currentId].push({
                                        colName: key,
                                        error: true,
                                        colErrorMessage: `Max 3000 characters are allowed in ${
                                            key === 'dashletDescription'
                                                ? 'dashlet description'
                                                : key === 'recommendations'
                                                ? 'recommendations'
                                                : 'benchmark'
                                        }`
                                    });
                                }
                            }
                            setReportElementError(oldData);
                        } else {
                            setReportElementError((previous: any) => ({
                                ...previous,

                                [currentId]: [
                                    {
                                        colName: key,
                                        error: true,
                                        colErrorMessage: `Max 3000 characters are allowed in ${
                                            key === 'dashletDescription'
                                                ? 'dashlet description'
                                                : key === 'recommendations'
                                                ? 'recommendations'
                                                : 'benchmark'
                                        }`
                                    }
                                ]
                            }));
                        }
                    } else {
                        const oldData = cloneDeep(reportElementError);

                        if (
                            oldData[currentId] &&
                            oldData[currentId].length > 0
                        ) {
                            const newArr = oldData[currentId].filter(
                                (current: any) => current.colName !== key
                            );
                            if (newArr.length === 0) {
                                delete oldData[currentId];
                            } else {
                                oldData[currentId] = newArr;
                            }
                        }
                        setReportElementError(oldData);
                    }
                }

                if (key === 'dashletDescription') {
                    // @ts-ignore
                    tempState.reportlet_data[
                        index
                    ].reportlet!.reportDashlet!.dashletDescription = e;
                } else if (key === 'recommendations') {
                    // @ts-ignore
                    tempState.reportlet_data[index].reportlet!.recommendations =
                        e;
                    // item.reportlet.recommendations
                } else if (key === 'benchmark') {
                    // @ts-ignore
                    tempState.reportlet_data[index].reportlet!.benchmark = e;
                } else if (key === 'layout') {
                    // @ts-ignore
                    tempState.reportlet_data[index].reportlet!.layout =
                        // @ts-ignore
                        e.target.value;
                }
            }

            setReportFieldsData(tempState);
        }
    };

    const handleCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        item: ReportFieldDataContentType,
        key: string
    ) => {
        const tempState = { ...reportFieldsData };

        setIsFormDirty(true);

        const index = tempState.reportlet_data.findIndex(
            object => object.dragAndDropId === item.dragAndDropId
        );

        // @ts-ignore
        tempState.reportlet_data[index].reportlet[key] = e.target.checked;

        setReportFieldsData(tempState);
    };

    const deleteItem = (id: string | undefined) => {
        const tempReportFieldsData = { ...reportFieldsData };

        const removeIndex = tempReportFieldsData.reportlet_data.findIndex(
            element => element.dragAndDropId === id
        );

        if (removeIndex !== -1) {
            tempReportFieldsData.reportlet_data.splice(removeIndex, 1);
        }

        setReportFieldsData(tempReportFieldsData);

        const updatedReportElementError = cloneDeep(reportElementError);
        delete updatedReportElementError[id || ''];
        setReportElementError(updatedReportElementError);
    };

    const isFieldHavingError = (
        dragId: string | undefined,
        colName: string
    ) => {
        const colId = dragId || '';

        if (reportElementError[colId]) {
            const index = reportElementError[colId].findIndex(
                (single: any) => single.colName === colName
            );

            if (index < 0) {
                return false;
            }

            return reportElementError[colId][index].error;
        }

        return false;
    };

    const getFieldErrorMessage = (
        dragId: string | undefined,
        colName: string
    ) => {
        const colId = dragId || '';

        if (reportElementError[colId]) {
            const index = reportElementError[colId].findIndex(
                (single: any) => single.colName === colName
            );
            if (index >= 0 && reportElementError[colId][index].error) {
                return reportElementError[colId][index].colErrorMessage;
            }
        }

        return '';
    };

    const renderItem = (item: ReportFieldDataContentType) => {
        if (!item.isReportlet) {
            if (item.reportElement?.elementType === 'line_break') {
                return (
                    <Card
                        sx={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, .05)'
                                    : '#F2F2F4',
                            padding: '10px',
                            boxShadow:
                                item.dragAndDropId ===
                                selectedDashboardIds.dragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                height: '15px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '15px',
                                    background: '#CBCBCB',
                                    borderRadius: '6px'
                                }}
                            />
                            <IconButton
                                className="Icon-Hover-Effect"
                                onClick={() => deleteItem(item.dragAndDropId)}
                                sx={{
                                    float: 'right',
                                    top: '-10px',
                                    height: '24px'
                                }}
                            >
                                <CrossIcon />
                            </IconButton>
                        </div>
                    </Card>
                );
            }
            if (item.reportElement?.elementType === 'page_break') {
                return (
                    <Card
                        sx={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, .05)'
                                    : '#F2F2F4',
                            padding: '10px',
                            boxShadow:
                                item.dragAndDropId ===
                                selectedDashboardIds.dragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                height: '15px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '15px',
                                    // background: '#CBCBCB',
                                    borderRadius: '6px',
                                    // border: '8px dashed #CBCBCB'
                                    background:
                                        'repeating-linear-gradient(90deg, grey 0 35px, #0000 0 50px)'
                                }}
                            />
                            <IconButton
                                className="Icon-Hover-Effect"
                                onClick={() => deleteItem(item.dragAndDropId)}
                                sx={{
                                    float: 'right',
                                    top: '-10px',
                                    height: '24px'
                                }}
                            >
                                <CrossIcon />
                            </IconButton>
                        </div>
                    </Card>
                );
            }
            if (item.reportElement?.elementType === 'overview_text') {
                return (
                    <Card
                        sx={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, .05)'
                                    : '#F2F2F4',
                            padding: '10px',
                            boxShadow:
                                item.dragAndDropId ===
                                selectedDashboardIds.dragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    >
                        <StyledField>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    height: '30px'
                                }}
                            >
                                <FormLabel
                                    htmlFor={`modal-field-input-${
                                        item.dragAndDropId as string
                                    }`}
                                >
                                    {OVERVIEW_TEXT}
                                </FormLabel>
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    onClick={() =>
                                        deleteItem(item.dragAndDropId)
                                    }
                                    sx={{
                                        float: 'right',
                                        top: '-10px',
                                        height: '24px'
                                    }}
                                >
                                    <CrossIcon />
                                </IconButton>
                            </div>
                            {/* <div data-text-editor="form-editor"> */}
                            <StyledQuillDiv>
                                <ReactQuill
                                    // bounds={`[data-text-editor="form-editor"]`}
                                    className={
                                        isFieldHavingError(
                                            item.dragAndDropId,
                                            'overview_text'
                                        )
                                            ? 'ql-error'
                                            : ''
                                    }
                                    formats={formats}
                                    modules={modules}
                                    onChange={(e, data, source, editor) => {
                                        handleTextChange(
                                            e,
                                            item,
                                            'overview_text',
                                            editor,
                                            source
                                        );
                                    }}
                                    style={{
                                        fontFamily: 'Proxima Nova W08 Reg'
                                    }}
                                    value={item.reportElement.value}
                                />
                                {isFieldHavingError(
                                    item.dragAndDropId,
                                    'overview_text'
                                ) && (
                                    <FormHelperText sx={{ color: '#e31c3d' }}>
                                        {getFieldErrorMessage(
                                            item.dragAndDropId,
                                            'overview_text'
                                        )}
                                    </FormHelperText>
                                )}
                            </StyledQuillDiv>
                        </StyledField>
                    </Card>
                );
            }
            if (
                item.reportElement?.elementType === 'segment_overview_title' ||
                item.reportElement?.elementType === 'segment_title'
            ) {
                return (
                    <Card
                        sx={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, .05)'
                                    : '#F2F2F4',
                            padding: '10px',
                            boxShadow:
                                item.dragAndDropId ===
                                selectedDashboardIds.dragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    >
                        <StyledField>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    height: '30px'
                                }}
                            >
                                <FormLabel
                                    htmlFor={`modal-field-input-${
                                        item.dragAndDropId as string
                                    }`}
                                >
                                    {item.reportElement.elementType ===
                                        'segment_overview_title' &&
                                        SEGMENT_OVERVIEW_TITLE}
                                    {item.reportElement.elementType ===
                                        'segment_title' && SEGMENT_TITLE}
                                </FormLabel>
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    onClick={() =>
                                        deleteItem(item.dragAndDropId)
                                    }
                                    sx={{
                                        float: 'right',
                                        top: '-10px',
                                        height: '24px'
                                    }}
                                >
                                    <CrossIcon />
                                </IconButton>
                            </div>
                            <TextBox
                                autoComplete="off"
                                error={isFieldHavingError(
                                    item.dragAndDropId,
                                    item.reportElement.elementType
                                )}
                                fullWidth
                                helperText={getFieldErrorMessage(
                                    item.dragAndDropId,
                                    item.reportElement.elementType
                                )}
                                hiddenLabel
                                id={`modal-field-input-${
                                    item.dragAndDropId as string
                                }`}
                                InputProps={{
                                    endAdornment: item.reportElement.value
                                        ?.length > 0 && (
                                        <InputAdornment
                                            onClick={() =>
                                                handleTextChange(
                                                    'clear',
                                                    item,
                                                    item.reportElement
                                                        ?.elementType
                                                )
                                            }
                                            position="end"
                                            variant="outlined"
                                        >
                                            <IoIosClose />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={e =>
                                    handleTextChange(
                                        e,
                                        item,
                                        item.reportElement?.elementType
                                    )
                                }
                                size="small"
                                value={item.reportElement.value}
                                variant="outlined"
                            />
                        </StyledField>
                    </Card>
                );
            }
        }

        let layoutItems = [];

        if (
            item.reportlet?.reportDashlet.dashletJson.dashlet
                .visualizationConfig.type === 'TABLE' ||
            item.reportlet?.reportDashlet.dashletJson.dashlet
                .visualizationConfig.type === 'COLUMN' ||
            item.reportlet?.reportDashlet.dashletJson.dashlet
                .visualizationConfig.type === 'LINE'
        ) {
            layoutItems = ['LEFT', 'RIGHT', 'ABOVE', 'BELOW'];
        } else {
            layoutItems = ['LEFT', 'RIGHT'];
        }
        return (
            <Card
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, .05)'
                            : '#F2F2F4',
                    padding: '10px',
                    boxShadow:
                        item.dragAndDropId ===
                        selectedDashboardIds.dragAndDropId
                            ? boxShadowString
                            : ''
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <StyledField>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Checkbox
                                    id={`modal-field-input-dashlet-hide-section-${
                                        item.dragAndDropId as string
                                    }`}
                                    checked={item.reportlet?.hideSection}
                                    onChange={e =>
                                        handleCheckboxChange(
                                            e,
                                            item,
                                            'hideSection'
                                        )
                                    }
                                    style={{
                                        transform: 'scale(0.8)'
                                    }}
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '5px'
                                    }}
                                />
                                <FormLabel
                                    htmlFor={`modal-field-input-dashlet-hide-section-${
                                        item.dragAndDropId as string
                                    }`}
                                >
                                    {HIDE_DASHLET}
                                </FormLabel>
                            </div>
                            <IconButton
                                className="Icon-Hover-Effect"
                                onClick={() => deleteItem(item.dragAndDropId)}
                                sx={{
                                    float: 'right',
                                    top: '-10px',
                                    height: '24px'
                                }}
                            >
                                <CrossIcon />
                            </IconButton>
                        </div>
                    </StyledField>

                    <StyledField>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                height: '30px'
                            }}
                        >
                            <FormLabel
                                htmlFor={`modal-field-input-dashlet-${
                                    item.dragAndDropId as string
                                }`}
                            >
                                {DASHLET}
                            </FormLabel>
                        </div>
                        <TextBox
                            autoComplete="off"
                            disabled
                            fullWidth
                            hiddenLabel
                            id={`modal-field-input-dashlet-${
                                item.dragAndDropId as string
                            }`}
                            onChange={() => {}}
                            size="small"
                            value={
                                item.reportlet?.reportDashlet.title as string
                            }
                            variant="outlined"
                        />
                    </StyledField>

                    <StyledField>
                        <FormLabel
                            htmlFor={`modal-field-input-dashlet-description-${
                                item.dragAndDropId as string
                            }`}
                        >
                            {DASHLET_DESCRIPTION}
                        </FormLabel>

                        <StyledQuillDiv>
                            <ReactQuill
                                // bounds={`[data-text-editor="form-editor"]`}
                                className={
                                    isFieldHavingError(
                                        item.dragAndDropId,
                                        'dashletDescription'
                                    )
                                        ? 'ql-error'
                                        : ''
                                }
                                formats={formats}
                                modules={modules}
                                onChange={(e, data, source, editor) => {
                                    handleTextChange(
                                        e,
                                        item,
                                        'dashletDescription',
                                        editor,
                                        source
                                    );
                                }}
                                value={
                                    item.reportlet?.reportDashlet
                                        .dashletDescription
                                }
                            />
                            {isFieldHavingError(
                                item.dragAndDropId,
                                'dashletDescription'
                            ) && (
                                <FormHelperText sx={{ color: '#e31c3d' }}>
                                    {getFieldErrorMessage(
                                        item.dragAndDropId,
                                        'dashletDescription'
                                    )}
                                </FormHelperText>
                            )}
                        </StyledQuillDiv>
                    </StyledField>

                    <StyledField>
                        <FormLabel
                            htmlFor={`modal-field-input-layout-${
                                item.dragAndDropId as string
                            }`}
                        >
                            {LAYOUT}
                        </FormLabel>

                        <Select
                            defaultValue={item.reportlet?.layout || 'LEFT'}
                            onChange={e => handleTextChange(e, item, 'layout')}
                            variant="outlined"
                        >
                            {layoutItems.map(layout => (
                                <MenuItem key={layout} value={layout}>
                                    {layout}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledField>

                    <StyledField>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '30px'
                            }}
                        >
                            <Checkbox
                                id={`modal-field-input-dashlet-recommendations-${
                                    item.dragAndDropId as string
                                }`}
                                checked={
                                    item.reportlet?.recommendationsCheckbox
                                }
                                onChange={e =>
                                    handleCheckboxChange(
                                        e,
                                        item,
                                        'recommendationsCheckbox'
                                    )
                                }
                                style={{
                                    transform: 'scale(0.8)'
                                }}
                                sx={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px'
                                }}
                            />
                            <FormLabel
                                htmlFor={`modal-field-input-dashlet-recommendations-${
                                    item.dragAndDropId as string
                                }`}
                            >
                                {RECOMMENDATIONS}
                            </FormLabel>
                        </div>

                        <StyledQuillDiv>
                            <ReactQuill
                                // bounds={`[data-text-editor="form-editor"]`}
                                className={
                                    isFieldHavingError(
                                        item.dragAndDropId,
                                        'recommendations'
                                    )
                                        ? 'ql-error'
                                        : ''
                                }
                                formats={formats}
                                modules={modules}
                                onChange={(e, data, source, editor) => {
                                    handleTextChange(
                                        e,
                                        item,
                                        'recommendations',
                                        editor,
                                        source
                                    );
                                }}
                                value={item.reportlet?.recommendations}
                            />
                            {isFieldHavingError(
                                item.dragAndDropId,
                                'recommendations'
                            ) && (
                                <FormHelperText sx={{ color: '#e31c3d' }}>
                                    {getFieldErrorMessage(
                                        item.dragAndDropId,
                                        'recommendations'
                                    )}
                                </FormHelperText>
                            )}
                        </StyledQuillDiv>
                    </StyledField>

                    <StyledField>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '30px'
                            }}
                        >
                            <Checkbox
                                id={`modal-field-input-dashlet-benchmark-${
                                    item.dragAndDropId as string
                                }`}
                                checked={item.reportlet?.benchmarkCheckbox}
                                onChange={e =>
                                    handleCheckboxChange(
                                        e,
                                        item,
                                        'benchmarkCheckbox'
                                    )
                                }
                                style={{
                                    transform: 'scale(0.8)'
                                }}
                                sx={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px'
                                }}
                            />
                            <FormLabel
                                htmlFor={`modal-field-input-dashlet-benchmark-${
                                    item.dragAndDropId as string
                                }`}
                            >
                                {BENCHMARKS}
                            </FormLabel>
                        </div>
                        <StyledQuillDiv>
                            <ReactQuill
                                // bounds={`[data-text-editor="form-editor"]`}
                                className={
                                    isFieldHavingError(
                                        item.dragAndDropId,
                                        'benchmark'
                                    )
                                        ? 'ql-error'
                                        : ''
                                }
                                formats={formats}
                                modules={modules}
                                onChange={(e, data, source, editor) => {
                                    handleTextChange(
                                        e,
                                        item,
                                        'benchmark',
                                        editor,
                                        source
                                    );
                                }}
                                value={item.reportlet?.benchmark}
                            />
                            {isFieldHavingError(
                                item.dragAndDropId,
                                'benchmark'
                            ) && (
                                <FormHelperText sx={{ color: '#e31c3d' }}>
                                    {getFieldErrorMessage(
                                        item.dragAndDropId,
                                        'benchmark'
                                    )}
                                </FormHelperText>
                            )}
                        </StyledQuillDiv>
                    </StyledField>
                </div>
            </Card>
        );
    };

    const renderChartSvg = (type: string) => {
        switch (type) {
            case 'COLUMN':
                return columnchart;
            case 'TABLE':
                return tablechart;
            case 'GAUGE':
                return gaugechart;
            case 'LINE':
                return linechart;
            case 'DONUT':
                return piechart;
            case 'COUNT':
                return singlevaluechart;
            case 'segment_title':
                return segmentTitle;
            case 'segment_overview_title':
                return segmentOverviewTitle;
            case 'overview_text':
                return overviewText;
            case 'line_break':
                return segmentDivider;
            case 'page_break':
                return PageBreak;
            default:
                return '';
        }
    };

    const renderSymbol = (item: ReportFieldDataContentType) => {
        if (!item.isReportlet) {
            const cardImage = renderChartSvg(item.reportElement!.elementType);

            if (
                item.reportElement?.elementType === 'segment_title' ||
                item.reportElement?.elementType === 'segment_overview_title' ||
                item.reportElement?.elementType === 'overview_text' ||
                item.reportElement?.elementType === 'page_break'
            ) {
                return (
                    <Card
                        sx={{
                            width: '140px',
                            boxShadow:
                                item.previewDragAndDropId ===
                                selectedDashboardIds.previewDragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    >
                        <CardMedia
                            alt="Your image"
                            component="img"
                            height="80"
                            image={cardImage}
                            sx={{
                                padding: '1em',
                                objectFit: 'contain',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background:
                                    theme.palette.mode === 'dark'
                                        ? ''
                                        : '#F2F2F4',
                                boxShadow: 'none'
                            }}
                            width={30}
                        />
                    </Card>
                );
            } else if (item.reportElement?.elementType === 'page_break') {
                return (
                    <div
                        style={{
                            width: '138px',
                            height: '10px',
                            borderRadius: '5px',
                            border: '5px dashed #CBCBCB',
                            boxShadow:
                                item.previewDragAndDropId ===
                                selectedDashboardIds.previewDragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    />
                );
            } else {
                return (
                    <div
                        style={{
                            width: '138px',
                            height: '10px',
                            borderRadius: '5px',
                            background: '#CBCBCB',
                            boxShadow:
                                item.previewDragAndDropId ===
                                selectedDashboardIds.previewDragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    />
                );
            }
        }

        const cardImage = renderChartSvg(
            item.reportlet!.reportDashlet.dashletJson.dashlet
                .visualizationConfig.type
        );

        return (
            <Card
                sx={{
                    width: '140px',
                    boxShadow:
                        item.previewDragAndDropId ===
                        selectedDashboardIds.previewDragAndDropId
                            ? boxShadowString
                            : ''
                }}
            >
                <CardMedia
                    alt="Your image"
                    component="img"
                    height="80"
                    image={cardImage}
                    sx={{
                        padding: '1em',
                        objectFit: 'contain',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background:
                            theme.palette.mode === 'dark' ? '' : '#F2F2F4',
                        boxShadow: 'none'
                    }}
                    width={30}
                />

                <CardContent>
                    <Tooltip
                        arrow
                        placement="bottom"
                        sx={{
                            '.MuiTooltip-tooltip': {
                                marginTop: '4px !important'
                            }
                        }}
                        title={
                            <div className="tooltip-arrow-text">
                                {item.reportlet!.reportDashlet.title}
                            </div>
                        }
                    >
                        <EllipsisTypography>
                            {item.reportlet!.reportDashlet.title}
                        </EllipsisTypography>
                    </Tooltip>
                </CardContent>
            </Card>
        );
    };

    const nextScroll = (type: string) => {
        const tempPageNumber = { ...pageNumber };

        if (
            type === 'dashlets' &&
            dashletData.totalPages >= tempPageNumber.dashletPageNumber + 2
        ) {
            // tempPageNumber.dashletPageNumber += 1;
            // console.log(tempPageNumber, dashletData.totalPages);
            // setPageNumber(tempPageNumber);
            setPageNumber(prev => {
                return {
                    ...prev,
                    dashletPageNumber: prev.dashletPageNumber + 1
                };
            });
        } else if (
            type === 'reportlets' &&
            reportletData.totalPages >= tempPageNumber.reportletPageNumber + 2
        ) {
            // tempPageNumber.reportletPageNumber += 1;
            // setPageNumber(tempPageNumber);

            setPageNumber(prev => {
                return {
                    ...prev,
                    reportletPageNumber: prev.reportletPageNumber + 1
                };
            });
        }
    };

    const handleFilter = (e: SelectChangeEvent<unknown>) => {
        setSelectedTenantId(e.target.value as string);
    };

    const setAllDashletData = async () => {
        if (selectedTenantId !== 'All') {
            getTenantDashlets(
                selectedTenantId,
                pageNumber.dashletPageNumber,
                10,
                selectedDashboards.join()
            )
                .then(res => {
                    if (Object.keys(dashletData).length === 0) {
                        setDashletData(res.data);
                    } else {
                        const tempDashletData = { ...dashletData };
                        tempDashletData.content = [
                            ...tempDashletData.content,
                            ...res.data.content
                        ];
                        setDashletData(tempDashletData);
                    }
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        } else {
            getDashlets(pageNumber.dashletPageNumber, 10)
                .then(res => {
                    if (!dashletData.content) {
                        setDashletData(res.data);
                    } else {
                        const tempDashletData = { ...dashletData };

                        tempDashletData.content = [
                            ...tempDashletData.content,
                            ...res.data.content
                        ];
                        setDashletData(tempDashletData);
                    }
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        }
    };

    const setDashletDataOnTenantChange = async () => {
        if (selectedTenantId !== 'All') {
            const dashboardData: MenuItemProps[] = [];
            const selectedDashboard: number[] = [];

            await getTenantDashboards(selectedTenantId)
                .then(res => {
                    res.data.forEach((dashboard: any) => {
                        const obj = {} as MenuItemProps;
                        obj.id = dashboard.tenantDashboardId;
                        obj.label = dashboard.name;
                        obj.labelRender = <span>{dashboard.name}</span>;
                        obj.checkStatus = 'checked';

                        dashboardData.push(obj);
                        selectedDashboard.push(dashboard.tenantDashboardId);
                    });
                    setSelectedDashboards(selectedDashboard);
                    setDashboardList(dashboardData);

                    getTenantDashlets(
                        selectedTenantId,
                        0,
                        10,
                        selectedDashboard.join()
                    )
                        .then(res => setDashletData(res.data))
                        .catch(err => {
                            displayErrorMessage(err);
                        });
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        } else {
            getDashlets(0, 10)
                .then(res => setDashletData(res.data))
                .catch(err => {
                    displayErrorMessage(err);
                });
        }
    };

    const setDashletDataonDashboardChange = async () => {
        setDashletData({} as DashletDataType);
        if (selectedTenantId !== 'All') {
            const tempDashboardList = [...dashboardList];

            tempDashboardList.forEach(dashboard => {
                if (
                    selectedDashboards.some((ele: any) => ele === dashboard.id)
                ) {
                    dashboard.checkStatus = 'checked';
                } else {
                    dashboard.checkStatus = 'unchecked';
                }
            });

            setDashboardList(tempDashboardList);

            await getTenantDashlets(
                selectedTenantId,
                pageNumber.dashletPageNumber,
                10,
                selectedDashboards.join()
            )
                .then(res => setDashletData(res.data))
                .catch(err => {
                    displayErrorMessage(err);
                });
        } else {
            getDashlets(pageNumber.dashletPageNumber, 10)
                .then(res => setDashletData(res.data))
                .catch(err => {
                    displayErrorMessage(err);
                });
        }
    };

    const selectedReportletData = async () => {
        setReportletData({} as ReportletDataType);
        await getAllReportlets(
            pageNumber.reportletPageNumber,
            10,
            selectedReports.join()
        )
            .then(res => setReportletData(res.data))
            .catch(err => {
                displayErrorMessage(err);
            });
    };

    const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);

    const accordions = [
        {
            id: 'dashlets',
            label: `All Tenant Dashlets (${dashletData.totalElements ?? 0})`,
            children: (
                <StyledDiv
                    id="scrollableDashletDiv"
                    style={{
                        height: '42vh',
                        overflowX: 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <InfiniteScroll
                        className="infinite-scroll"
                        dataLength={dashletData?.content?.length ?? 0}
                        hasMore={
                            dashletData?.totalElements !== 0 &&
                            dashletData?.content?.length !==
                                dashletData?.totalElements
                        }
                        loader={<h4>Loading...</h4>}
                        next={() => nextScroll('dashlets')}
                        scrollableTarget="scrollableDashletDiv"
                        style={{
                            overflow: 'hidden'
                        }}
                    >
                        <Droppable
                            droppableId="dashlets"
                            isDropDisabled
                            type="report-items"
                        >
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    // @ts-ignore
                                    // isDraggingOver={snapshot.isDraggingOver}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <StyledField>
                                                <FormLabel htmlFor="dashboard-selection">
                                                    {TENANT}
                                                </FormLabel>
                                                <Select
                                                    defaultValue="All"
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left'
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left'
                                                        }
                                                    }}
                                                    onChange={e =>
                                                        handleFilter(e)
                                                    }
                                                    style={{
                                                        minWidth: 156,
                                                        maxWidth: 156
                                                    }}
                                                    variant="outlined"
                                                >
                                                    <MenuItem value="All">
                                                        All
                                                    </MenuItem>
                                                    {tenantData.map(tenant => (
                                                        <MenuItem
                                                            key={tenant.id}
                                                            value={tenant.id}
                                                        >
                                                            {tenant.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </StyledField>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <StyledField>
                                                <FormLabel htmlFor="dashboard-selection">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between'
                                                        }}
                                                    >
                                                        {DASHBOARD}
                                                        <Tooltip
                                                            arrow
                                                            placement="bottom"
                                                            sx={{
                                                                '.MuiTooltip-tooltip':
                                                                    {
                                                                        marginTop:
                                                                            '4px !important'
                                                                    }
                                                            }}
                                                            title={
                                                                selectedTenantId ===
                                                                    'All' ||
                                                                selectedDashboards.length !==
                                                                    1 ? null : (
                                                                    <div className="tooltip-arrow-text">
                                                                        Move All
                                                                        Dashlets
                                                                    </div>
                                                                )
                                                            }
                                                        >
                                                            <IconButton
                                                                disabled={
                                                                    selectedTenantId ===
                                                                        'All' ||
                                                                    selectedDashboards.length !==
                                                                        1
                                                                }
                                                                style={{
                                                                    marginLeft:
                                                                        'auto',
                                                                    backgroundColor:
                                                                        selectedTenantId ===
                                                                            'All' ||
                                                                        selectedDashboards.length !==
                                                                            1
                                                                            ? 'transparent'
                                                                            : ''
                                                                }}
                                                                onClick={
                                                                    copyAllDashboards
                                                                }
                                                            >
                                                                <FaArrowRight
                                                                    fontSize="small"
                                                                    className="close"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </FormLabel>
                                                <Select
                                                    defaultValue="All Dashboards"
                                                    disabled={
                                                        selectedTenantId ===
                                                        'All'
                                                    }
                                                    // fullWidth
                                                    id="dashboard-selection"
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left'
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left'
                                                        }
                                                    }}
                                                    onClose={() =>
                                                        setDashletDataonDashboardChange()
                                                    }
                                                    renderValue={() =>
                                                        dashboardCount
                                                    }
                                                    style={{
                                                        minWidth: 156,
                                                        maxWidth: 156
                                                    }}
                                                    value={dashboardCount}
                                                    variant="outlined"
                                                >
                                                    <MultiSelect
                                                        isAllSelected
                                                        items={dashboardList}
                                                        onSelectionChanged={selectedItems => {
                                                            setSelectedDashboards(
                                                                selectedItems.map(
                                                                    (
                                                                        element: any
                                                                    ) =>
                                                                        element.id
                                                                )
                                                            );
                                                        }}
                                                        showSelectAllOption
                                                    />
                                                </Select>
                                            </StyledField>
                                        </Grid>

                                        {dashletData?.content?.length > 0 ? (
                                            <>
                                                <div />
                                                {dashletData?.content?.map(
                                                    (
                                                        dashlet,
                                                        index: number
                                                    ) => {
                                                        const cardImage =
                                                            renderChartSvg(
                                                                dashlet
                                                                    .dashletJson
                                                                    .dashlet
                                                                    .visualizationConfig
                                                                    .type
                                                            );

                                                        return (
                                                            <Draggable
                                                                key={dashlet.id}
                                                                draggableId={
                                                                    dashlet.id
                                                                }
                                                                index={index}
                                                            >
                                                                {dragprovided => (
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                    >
                                                                        <Card
                                                                            ref={
                                                                                dragprovided.innerRef
                                                                            }
                                                                            {...dragprovided.draggableProps}
                                                                            {...dragprovided.dragHandleProps}
                                                                            // @ts-ignore
                                                                            // isDragging={
                                                                            //     snapshot.isDragging
                                                                            // }
                                                                            // style={{
                                                                            //     zIndex: 10003
                                                                            // }}
                                                                            style={
                                                                                dragprovided
                                                                                    .draggableProps
                                                                                    .style
                                                                            }
                                                                        >
                                                                            <CardMedia
                                                                                alt="Your image"
                                                                                component="img"
                                                                                height="80"
                                                                                image={
                                                                                    cardImage
                                                                                }
                                                                                sx={{
                                                                                    padding:
                                                                                        '1em',
                                                                                    objectFit:
                                                                                        'contain',
                                                                                    alignContent:
                                                                                        'center',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    justifyContent:
                                                                                        'center'
                                                                                }}
                                                                            />
                                                                            <CardContent>
                                                                                <Tooltip
                                                                                    arrow
                                                                                    placement="bottom"
                                                                                    sx={{
                                                                                        '.MuiTooltip-tooltip':
                                                                                            {
                                                                                                marginTop:
                                                                                                    '4px !important'
                                                                                            }
                                                                                    }}
                                                                                    title={
                                                                                        <div className="tooltip-arrow-text">
                                                                                            {
                                                                                                dashlet.title
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <EllipsisTypography>
                                                                                        {
                                                                                            dashlet.title
                                                                                        }
                                                                                    </EllipsisTypography>
                                                                                </Tooltip>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                )}
                                            </>
                                        ) : (
                                            expandedPanel === 'dashlets' && (
                                                <div
                                                    style={{
                                                        display: 'flex',

                                                        flexDirection: 'column',
                                                        textAlign: 'center',
                                                        marginLeft: '33%',
                                                        marginTop: '8%'
                                                    }}
                                                >
                                                    <TableNoDataIcon
                                                        style={{
                                                            height: '150px',
                                                            width: '150px'
                                                        }}
                                                    />
                                                    <StyledTypography variant="h3">
                                                        {NO_DATA_FOUND}
                                                    </StyledTypography>
                                                </div>
                                            )
                                        )}
                                    </Grid>
                                </div>
                            )}
                        </Droppable>
                    </InfiniteScroll>
                </StyledDiv>
            )
        },
        {
            id: 'reportlets',
            label: `All Reportlets (${reportletData.totalElements ?? 0})`,
            children: (
                <StyledDiv
                    id="scrollableReportletDiv"
                    style={{
                        height: '42vh',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <InfiniteScroll
                        dataLength={reportletData?.content?.length ?? 0}
                        hasMore={
                            reportletData?.totalElements !== 0 &&
                            reportletData?.content?.length !==
                                reportletData?.totalElements
                        }
                        loader={<h4>Loading...</h4>}
                        next={() => nextScroll('reportlets')}
                        scrollableTarget="scrollableReportletDiv"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden'
                        }}
                    >
                        <Droppable
                            droppableId="reportlets"
                            isDropDisabled
                            type="report-items"
                        >
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    // @ts-ignore
                                    // isDraggingOver={snapshot.isDraggingOver}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <StyledField>
                                                <FormLabel htmlFor="dashboard-selection">
                                                    {REPORT}
                                                </FormLabel>
                                                <Select
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left'
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left'
                                                        }
                                                    }}
                                                    onClose={() =>
                                                        selectedReportletData()
                                                    }
                                                    renderValue={() =>
                                                        reportCount
                                                    }
                                                    style={{
                                                        minWidth: 156,
                                                        maxWidth: 156
                                                    }}
                                                    value={reportCount}
                                                    variant="outlined"
                                                >
                                                    <MultiSelect
                                                        isAllSelected
                                                        items={reportList}
                                                        onSelectionChanged={selectedItems => {
                                                            setSelectedReports(
                                                                selectedItems.map(
                                                                    element =>
                                                                        element.id
                                                                )
                                                            );
                                                        }}
                                                        showSelectAllOption
                                                    />
                                                </Select>
                                            </StyledField>
                                        </Grid>
                                        <Grid item xs={6} />

                                        {reportletData?.content?.length > 0 ? (
                                            <>
                                                <div />
                                                {reportletData?.content?.map(
                                                    (
                                                        reportlet,
                                                        index: number
                                                    ) => {
                                                        const cardImage =
                                                            renderChartSvg(
                                                                reportlet
                                                                    .reportDashlet
                                                                    .dashletJson
                                                                    .dashlet
                                                                    .visualizationConfig
                                                                    .type
                                                            );

                                                        return (
                                                            <Draggable
                                                                key={
                                                                    reportlet.id
                                                                }
                                                                draggableId={
                                                                    reportlet.id
                                                                }
                                                                index={index}
                                                            >
                                                                {dragProvided => (
                                                                    <Grid
                                                                        ref={
                                                                            dragProvided.innerRef
                                                                        }
                                                                        // isDragging={
                                                                        //     snapshot.isDragging
                                                                        // }
                                                                        item
                                                                        {...dragProvided.draggableProps}
                                                                        {...dragProvided.dragHandleProps}
                                                                        // @ts-ignore

                                                                        style={
                                                                            dragProvided
                                                                                .draggableProps
                                                                                .style
                                                                        }
                                                                        xs={6}
                                                                    >
                                                                        <Card
                                                                        // style={{
                                                                        //     zIndex: 10003
                                                                        // }}
                                                                        >
                                                                            <CardMedia
                                                                                alt="Your image"
                                                                                component="img"
                                                                                height="80"
                                                                                image={
                                                                                    cardImage
                                                                                }
                                                                                sx={{
                                                                                    padding:
                                                                                        '1em',
                                                                                    objectFit:
                                                                                        'contain',
                                                                                    alignContent:
                                                                                        'center',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    justifyContent:
                                                                                        'center'
                                                                                }}
                                                                            />
                                                                            <CardContent>
                                                                                <Tooltip
                                                                                    arrow
                                                                                    placement="bottom"
                                                                                    sx={{
                                                                                        '.MuiTooltip-tooltip':
                                                                                            {
                                                                                                marginTop:
                                                                                                    '4px !important'
                                                                                            }
                                                                                    }}
                                                                                    title={
                                                                                        <div className="tooltip-arrow-text">
                                                                                            {
                                                                                                reportlet
                                                                                                    .reportDashlet
                                                                                                    .title
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <EllipsisTypography>
                                                                                        {
                                                                                            reportlet
                                                                                                .reportDashlet
                                                                                                .title
                                                                                        }
                                                                                    </EllipsisTypography>
                                                                                </Tooltip>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                )}
                                            </>
                                        ) : (
                                            expandedPanel === 'reportlets' && (
                                                <div
                                                    style={{
                                                        display: 'flex',

                                                        flexDirection: 'column',
                                                        textAlign: 'center',
                                                        marginLeft: '33%',
                                                        marginTop: '8%'
                                                    }}
                                                >
                                                    <TableNoDataIcon
                                                        style={{
                                                            height: '150px',
                                                            width: '150px'
                                                        }}
                                                    />
                                                    <StyledTypography variant="h3">
                                                        {NO_DATA_FOUND}
                                                    </StyledTypography>
                                                </div>
                                            )
                                        )}
                                    </Grid>
                                </div>
                            )}
                        </Droppable>
                    </InfiniteScroll>
                </StyledDiv>
            )
        },
        {
            id: 'reportElements',
            label: `Report Elements (5)`,
            children: (
                <StyledDiv
                    id="scrollableDashletDiv"
                    style={{
                        height: '42vh',
                        overflowX: 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Droppable
                        droppableId="report-elements"
                        isDropDisabled
                        type="report-items"
                    >
                        {provided => (
                            <div
                                ref={provided.innerRef}
                                // @ts-ignore
                                // isDraggingOver={snapshot.isDraggingOver}
                            >
                                <Grid container spacing={2}>
                                    {ITEMS.map((element, index) => {
                                        let cardImage = renderChartSvg(
                                            element.type
                                        );

                                        if (element.type === 'segment_title') {
                                            cardImage = segmentTitle;
                                        } else if (
                                            element.type ===
                                            'segment_overview_title'
                                        ) {
                                            cardImage = segmentOverviewTitle;
                                        } else if (
                                            element.type === 'overview_text'
                                        ) {
                                            cardImage = overviewText;
                                        } else if (
                                            element.type === 'line_break'
                                        ) {
                                            cardImage = segmentDivider;
                                        }

                                        return (
                                            <Draggable
                                                key={element.id}
                                                draggableId={element.id}
                                                index={index}
                                            >
                                                {dragProvided => (
                                                    <Grid
                                                        ref={
                                                            dragProvided.innerRef
                                                        }
                                                        // isDragging={
                                                        //     snapshot.isDragging
                                                        // }
                                                        item
                                                        style={
                                                            dragProvided
                                                                .draggableProps
                                                                .style
                                                        }
                                                        xs={6}
                                                        {...dragProvided.draggableProps}
                                                        {...dragProvided.dragHandleProps}
                                                        // @ts-ignore
                                                    >
                                                        <Card>
                                                            <CardMedia
                                                                alt="Your image"
                                                                component="img"
                                                                height="80"
                                                                image={
                                                                    cardImage
                                                                }
                                                                sx={{
                                                                    padding:
                                                                        '1em',
                                                                    objectFit:
                                                                        'contain',
                                                                    alignContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center'
                                                                }}
                                                            />
                                                            <CardContent>
                                                                <Tooltip
                                                                    arrow
                                                                    placement="bottom"
                                                                    sx={{
                                                                        '.MuiTooltip-tooltip':
                                                                            {
                                                                                marginTop:
                                                                                    '4px !important'
                                                                            }
                                                                    }}
                                                                    title={
                                                                        <div className="tooltip-arrow-text">
                                                                            {
                                                                                element.label
                                                                            }
                                                                        </div>
                                                                    }
                                                                >
                                                                    <EllipsisTypography>
                                                                        {
                                                                            element.label
                                                                        }
                                                                    </EllipsisTypography>
                                                                </Tooltip>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                </Grid>
                            </div>
                        )}
                    </Droppable>
                </StyledDiv>
            )
        }
    ];

    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMenu(event.currentTarget);
    };

    const openMenu = Boolean(anchorElMenu);

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const downloadJSON = () => {
        const reportJson = cloneDeep(reportFieldsData.reportlet_data);

        for (let i = 0; i < reportJson?.length; i++) {
            reportJson[i].order = i + 1;

            delete reportJson[i].dragAndDropId;
            delete reportJson[i].previewDragAndDropId;
            if (reportJson[i].isReportlet) {
                delete reportJson[i].reportlet!.id;
                delete reportJson[i].reportlet!.reportId;
                delete reportJson[i].reportlet!.partnerId;

                delete reportJson[i].reportlet!.reportDashlet.id;
                delete reportJson[i].reportlet!.reportDashlet.partnerId;
                delete reportJson[i].reportlet!.reportDashlet.createdby;
                delete reportJson[i].reportlet!.reportDashlet.updateddate;
            } else {
                delete reportJson[i].reportElement!.id;
                delete reportJson[i].reportElement!.reportId;
                delete reportJson[i].reportElement!.partnerId;
                delete reportJson[i].reportElement!.createdby;
                delete reportJson[i].reportElement!.updateddate;
            }
        }

        const reportDataJson = {
            title: reportTitleAndName.title,
            name: reportTitleAndName.name,
            tags: reportId ? reportTitleAndName.tags : [],
            elements: reportJson
        };

        const dataStr = `data:text/json;charset=utf-8,
            ${encodeURIComponent(JSON.stringify(reportDataJson))}`;
        const dlAnchorElem = document.getElementById('downloadAnchorElem');
        dlAnchorElem?.setAttribute('href', dataStr);
        dlAnchorElem?.setAttribute(
            'download',
            `${reportTitleAndName.name}.json`
        );
        dlAnchorElem?.click();
    };

    const handleMenuItems = (value: string) => {
        if (
            !reportFieldError.titleError &&
            Object.keys(reportElementError).length === 0
        ) {
            if (!reportTitleAndName.title) {
                setReportFieldError(previous => ({
                    ...previous,
                    titleError: true,
                    titleHelperText: 'This field is required'
                }));
            } else {
                if (value === 'save_as') {
                    setIsSaveModalOpen(true);
                } else {
                    setReportFieldError(previous => ({
                        ...previous,
                        titleError: false,
                        titleHelperText: ''
                    }));

                    if (value === 'download_json') {
                        if (!reportTitleAndName.name) {
                            setIsDownloadJSONModalOpen(true);
                        } else {
                            downloadJSON();
                        }
                    }
                }
            }
        }

        handleCloseMenu();
    };

    const getSearchedTags = useEventCallback(
        (tagSearch: string) => {
            getTags(TagTypes.report, tagSearch)
                .then(res => {
                    setTags(
                        res.data.content
                            .map((tag: any) => tag.name)
                            .filter(
                                (tag: string) =>
                                    !reportTitleAndName.tags.includes(tag)
                            )
                    );
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        },
        // @ts-ignore
        [reportTitleAndName.tags]
    );

    const onTagsSearchInputChangeHandler = useMemo(
        () =>
            debounce((searchValue: string) => {
                getSearchedTags(searchValue);
            }, 500),
        [getSearchedTags]
    );

    const onClickSaveAsNewTemplate = () => {
        if (!reportTitleAndName.name) {
            setReportFieldError(previous => ({
                ...previous,
                templateNameError: true,
                templateHelperText: 'This field is required'
            }));
        } else {
            // eslint-disable-next-line
            if (reportId) {
                const tempReportData = cloneDeep(reportFieldsData);

                let order = 1;

                tempReportData.reportlet_data.forEach(report => {
                    delete report.dragAndDropId;
                    delete report.previewDragAndDropId;
                    report.order = order;
                    order++;
                    if (report.isReportlet) {
                        delete report.reportlet?.id;
                        delete report.reportlet?.reportDashlet.id;
                    } else {
                        delete report.reportElement?.id;
                    }
                });

                const postData = {
                    title: reportTitleAndName.title,
                    name: reportTitleAndName.name,
                    tags: reportTitleAndName.tags,
                    elements: tempReportData.reportlet_data
                };

                if (logoPath) {
                    // @ts-ignore
                    postData.logoPath = logoPath;
                }

                const formData = new FormData();
                if (icon?.length) {
                    formData.append('file', icon[0]);
                }

                // @ts-ignore
                formData.append(
                    'report',
                    new Blob([JSON.stringify(postData)], {
                        type: 'application/json'
                    })
                );

                if (
                    reportTitleAndName.title &&
                    reportTitleAndName.name.length
                ) {
                    createReport(formData)
                        .then(res => {
                            setAnchorElMenu(null);
                            showToast(
                                REPORT_CREATED_SUCCESSFULLY,
                                TOAST_TYPE.SUCCESS,
                                TOAST_ID
                            );
                            if (!isGenerateReport) {
                                navigate(
                                    `${TEMPLATE_ROUTE}${GET_REPORT_TEMPLATE}`
                                );
                            }
                            setIsSaveModalOpen(false);
                            setGeneratedReportId(res.data.id);
                            setIsFormDirty(false);
                            // setIsGenerateReport(false);
                            if (isGenerateReport) {
                                setIsGenerateModalOpen(true);
                            }
                        })
                        .catch(err => {
                            displayErrorMessage(err);
                        });
                } else {
                    showToast(REQUIRED_FIELD, TOAST_TYPE.ERROR, TOAST_ID);
                }
            } else {
                const tempReportData = cloneDeep(reportFieldsData);

                let order = 1;

                tempReportData.reportlet_data.forEach(report => {
                    delete report.dragAndDropId;
                    delete report.previewDragAndDropId;
                    report.order = order;
                    order++;
                });

                const postData = {
                    title: reportTitleAndName.title,
                    name: reportTitleAndName.name,
                    tags: reportTitleAndName.tags,
                    elements: tempReportData.reportlet_data
                };

                const formData = new FormData();

                if (icon?.length) {
                    formData.append('file', icon[0]);
                }

                // @ts-ignore
                formData.append(
                    'report',
                    new Blob([JSON.stringify(postData)], {
                        type: 'application/json'
                    })
                );

                if (
                    reportTitleAndName.title &&
                    reportTitleAndName.name.length
                ) {
                    createReport(formData)
                        .then(res => {
                            setAnchorElMenu(null);
                            // setIsNewTemplateModalOpen(false);
                            showToast(
                                REPORT_CREATED_SUCCESSFULLY,
                                TOAST_TYPE.SUCCESS,
                                TOAST_ID
                            );
                            if (!isGenerateReport) {
                                navigate(
                                    `${TEMPLATE_ROUTE}${GET_REPORT_TEMPLATE}`
                                );
                            }
                            setIsSaveModalOpen(false);
                            setGeneratedReportId(res.data.id);
                            setIsFormDirty(false);
                            if (isGenerateReport) {
                                setIsGenerateModalOpen(true);
                            }
                        })
                        .catch(err => {
                            displayErrorMessage(err);
                        });
                } else {
                    showToast(REQUIRED_FIELD, TOAST_TYPE.ERROR, TOAST_ID);
                }
            }
        }
    };

    const onReportPreviewSubmit = (type?: string) => {
        // @ts-ignore
        // setPreviewFiles([{ ...`previewFileObj`, fileType: type?.toLowerCase() }]);

        previewReportDownload(previewpdfFileObj.previewReportId, type).then(
            res => {
                // Create blob link to download
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${previewpdfFileObj.fileName}.${type}`
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode?.removeChild(link);
            }
        );
    };

    const onSaveAsExistingTemplate = () => {
        if (selectedTemplate === '') {
            setReportFieldError(previous => ({
                ...previous,
                existingTemplateError: true,
                existingTemplateHelperText: 'This field is required'
            }));
        } else {
            setReportFieldError(previous => ({
                ...previous,
                existingTemplateError: false,
                existingTemplateHelperText: ''
            }));

            const tempReportData = cloneDeep(reportFieldsData);

            let order = 1;

            tempReportData.reportlet_data.forEach(report => {
                delete report.dragAndDropId;
                delete report.previewDragAndDropId;
                report.order = order;
                order++;
                if (reportId) {
                    if (report.isReportlet) {
                        delete report.reportlet?.id;
                        delete report.reportlet?.reportDashlet.id;
                    } else {
                        delete report.reportElement?.id;
                    }
                }
            });

            // @ts-ignore
            const reportData = reportList[selectedTemplate];

            const postData = {
                title: reportTitleAndName.title,
                name: reportData.name,
                tags: reportData.tags,
                elements: tempReportData.reportlet_data
            };

            if (logoPath) {
                // @ts-ignore
                postData.logoPath = logoPath;
            }

            const formData = new FormData();
            if (icon?.length) {
                formData.append('file', icon[0]);
            }

            // @ts-ignore
            formData.append(
                'report',
                new Blob([JSON.stringify(postData)], {
                    type: 'application/json'
                })
            );

            if (reportTitleAndName.title) {
                updateReportDetails(reportData.id, formData)
                    .then(res => {
                        setAnchorElMenu(null);
                        showToast(
                            REPORT_TEMPLATE_UPDATED_SUCCESSFULLY,
                            TOAST_TYPE.SUCCESS,
                            TOAST_ID
                        );
                        setGeneratedReportId(res.data.id);
                        setIsSaveModalOpen(false);
                        setIsFormDirty(false);
                        if (isGenerateReport) {
                            setIsGenerateModalOpen(true);
                        } else {
                            navigate(`${TEMPLATE_ROUTE}${GET_REPORT_TEMPLATE}`);
                        }
                    })
                    .catch(err => {
                        displayErrorMessage(err);
                    });
            } else {
                showToast(REQUIRED_FIELD, TOAST_TYPE.ERROR, TOAST_ID);
            }
        }
    };

    useEffect(() => {
        if (state && state.data) {
            setReportTitleAndName(previous => ({
                ...previous,
                name: state.data.name,
                title: state.data.title
            }));
            const tempResponse = state.data.reportConfig.elements;

            tempResponse.forEach((report: any) => {
                report.dragAndDropId = uuid();
                report.previewDragAndDropId = uuid();
            });
            setReportFieldsData({
                // @ts-ignore
                reportlet_data: tempResponse
            });

            if (state?.data?.reportConfig?.logoPath) {
                setLogoPath(state.data.reportConfig.logoPath);
            }
        }
    }, []);

    useEffect(() => {
        const setReports = async () => {
            const reportData: MenuItemProps[] = [];
            const allSelectedReports: string[] = [];

            await getAllReports()
                .then(res => {
                    res.data.content.forEach((report: any) => {
                        const obj = {} as ReportDataMenuProp;
                        obj.id = report.id;
                        obj.label = report.name;
                        obj.labelRender = <span>{report.name}</span>;
                        obj.checkStatus = 'checked';

                        obj.tags = report.tags;

                        obj.name = report.name;

                        reportData.push(obj);
                        allSelectedReports.push(report.id);
                    });
                })
                .catch(err => {
                    displayErrorMessage(err);
                });

            setReportList(reportData);
            setSelectedReports(allSelectedReports);
        };

        setReports();

        setAllData();
    }, []);

    useEffect(() => {
        if (reportId) {
            getReportDetail(reportId)
                .then(res => {
                    setReportTitleAndName({
                        name: res.data.name,
                        title: res.data.title,
                        tags: res.data.tags
                    });
                    const tempResponse = res.data.elements;

                    tempResponse.forEach((report: any) => {
                        report.dragAndDropId = uuid();
                        report.previewDragAndDropId = uuid();
                    });
                    setReportFieldsData({
                        // @ts-ignore
                        reportlet_data: tempResponse
                    });

                    if (res?.data?.logoPath) {
                        setLogoPath(res.data.logoPath);
                    }

                    setGeneratedReportId(reportId);

                    setSelectedRadioButton('save');
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        }
    }, []);

    useEffect(() => {
        setAllDashletData();
    }, [pageNumber.dashletPageNumber]);

    useEffect(() => {
        setDashletData({} as DashletDataType);
        const tempPageNumber = { ...pageNumber };

        tempPageNumber.dashletPageNumber = 0;

        setPageNumber(tempPageNumber);
        setDashletDataOnTenantChange();

        if (selectedTenantId === 'All') {
            setDashboardCount('Select Dashboards');
        }
    }, [selectedTenantId]);

    useEffect(() => {
        const tempPageNumber = { ...pageNumber };
        tempPageNumber.dashletPageNumber = 0;
        setPageNumber(tempPageNumber);

        const totalDashboardSelected = selectedDashboards.length;
        if (totalDashboardSelected === 0) {
            setDashboardCount('Select Dashboards');
        } else if (totalDashboardSelected === 1) {
            const dashboardName = dashboardList.find(
                (element: any) => element.id === selectedDashboards[0]
            );
            setDashboardCount(dashboardName?.label!);
        } else if (totalDashboardSelected === dashboardList.length) {
            setDashboardCount('All Dashboards');
        } else {
            setDashboardCount(
                '%s Dashboards'.replace('%s', totalDashboardSelected.toString())
            );
        }
    }, [selectedDashboards]);

    useEffect(() => {
        const pageChangeReportletData = async () => {
            await getAllReportlets(
                pageNumber.reportletPageNumber,
                10,
                selectedReports.join()
            )
                .then(res => {
                    if (reportletData.content) {
                        const tempReportletData = { ...reportletData };
                        tempReportletData.content = [
                            ...tempReportletData.content,
                            ...res.data.content
                        ];
                        setReportletData(tempReportletData);
                    } else {
                        setReportletData(res.data);
                    }
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        };

        pageChangeReportletData();
    }, [pageNumber.reportletPageNumber]);

    useEffect(() => {
        const tempPageNumber = { ...pageNumber };

        tempPageNumber.reportletPageNumber = 0;

        setPageNumber(tempPageNumber);

        const totalReportSelected = selectedReports.length;
        if (totalReportSelected === 0) {
            setReportCount('Select Reports');
        } else if (totalReportSelected === 1) {
            const reportName = reportList.find(
                element => element.id === selectedReports[0]
            );
            setReportCount(reportName?.label!);
        } else if (totalReportSelected === reportList.length) {
            setReportCount('All Reports');
        } else {
            setReportCount(
                '%s Reports'.replace('%s', totalReportSelected.toString())
            );
        }

        const tempReportList = [...reportList];

        tempReportList.forEach(report => {
            if (
                selectedReports.some(
                    selectedReport => selectedReport === report.id
                )
            ) {
                report.checkStatus = 'checked';
            } else {
                report.checkStatus = 'unchecked';
            }
        });
    }, [selectedReports]);

    const onPreviewClick = () => {
        setGenerateModalType('preview');
        if (!reportFieldError.titleError && !reportTitleAndName.title) {
            setReportFieldError(previous => ({
                ...previous,
                titleError: true,
                titleHelperText: 'This field is required'
            }));
        } else if (
            !reportFieldError.titleError &&
            Object.keys(reportElementError).length === 0
        ) {
            setIsGenerateModalOpen(true);
        }
    };

    // To-do after download api woll be available
    const [previewReportIdAndName, setPreviewReportIdAndName] = useState({
        previewId: '',
        previewName: '',
        fileType: ''
    });

    const [shouldIntervalStart, setShouldIntervalStart] = useState(false);

    const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
    const [isDownloadJSONModalOpen, setIsDownloadJSONModalOpen] =
        useState<boolean>(false);

    const onSaveButtonClick = () => {
        setAnchorElMenu(null);

        const tempReportData = cloneDeep(reportFieldsData);

        let order = 1;

        tempReportData.reportlet_data.forEach(report => {
            delete report.dragAndDropId;
            delete report.previewDragAndDropId;
            report.order = order;
            order++;
        });

        const postData = {
            title: reportTitleAndName.title,
            name: reportTitleAndName.name,
            tags: reportTitleAndName.tags,
            elements: tempReportData.reportlet_data
        };

        if (logoPath) {
            // @ts-ignore
            postData.logoPath = logoPath;
        }

        const formData = new FormData();
        if (icon?.length) {
            formData.append('file', icon[0]);
        }

        formData.append(
            'report',
            new Blob([JSON.stringify(postData)], {
                type: 'application/json'
            })
        );

        if (reportTitleAndName.title && reportTitleAndName.name) {
            updateReportDetails(reportId, formData)
                .then(res => {
                    setAnchorElMenu(null);
                    showToast(
                        REPORT_UPDATE_SUCCESSFULLY,
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                    setGeneratedReportId(res.data.id);
                    setIsSaveModalOpen(false);
                    setIsFormDirty(false);
                    if (isGenerateReport) {
                        setIsGenerateModalOpen(true);
                    } else {
                        navigate(`${TEMPLATE_ROUTE}${GET_REPORT_TEMPLATE}`);
                    }
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        } else {
            showToast(REQUIRED_FIELD, TOAST_TYPE.ERROR, TOAST_ID);
        }
    };

    const onSaveModalSubmit = () => {
        if (selectedRadioButton === 'new_template') {
            onClickSaveAsNewTemplate();
        } else if (selectedRadioButton === 'existing_template') {
            onSaveAsExistingTemplate();
        } else if (selectedRadioButton === 'save') {
            onSaveButtonClick();
        }
    };

    const onGenerateReportSubmitSuccess = (previewData: any) => {
        setPreviewReportIdAndName(previewData);
        setIsGenerateModalOpen(false);
        setIsLoading(false);

        if (generateModalType === 'preview') {
            setPreviewFileType(previewData.fileType);
            setShouldIntervalStart(true);
            setIsPreviewModalOpen(true);
            setIsModalLoading(true);
        }

        let pdffileObj = {
            uri: `${axiosConfig.baseURL}${RUN_REPORT}/${previewData.previewId}/download?format=PDF`,
            fileType: 'pdf',
            fileName: previewData.previewName,
            previewReportId: previewData.previewId
        };

        let docfileObj = {
            uri: `${axiosConfig.baseURL}${RUN_REPORT}/${previewData.previewId}/download?format=DOC&isPreview=true`,
            fileType: 'pdf',
            fileName: previewData.previewName,
            previewReportId: previewData.previewId
        };

        let pptfileObj = {
            uri: `${axiosConfig.baseURL}${RUN_REPORT}/${previewData.previewId}/download?format=PPT&isPreview=true`,
            fileType: 'pdf',
            fileName: previewData.previewName,
            previewReportId: previewData.previewId
        };

        let jsonfileObj = {
            uri: `${axiosConfig.baseURL}${RUN_REPORT}/${previewData.previewId}/download?format=JSON`,
            fileType: 'json',
            fileName: previewData.previewName,
            previewReportId: previewData.previewId
        };

        // pdffileObj = {
        //     uri: 'https://sample-videos.com/pdf/Sample-pdf-5mb.pdf',
        //     fileType: 'pdf',
        //     fileName: 'demo.pdf',
        //     previewReportId: previewData.previewId
        // }

        // docfileObj = {
        //     uri: 'https://podcasts.ceu.edu/sites/podcasts.ceu.edu/files/sample.doc',
        //     fileType: 'doc',
        //     fileName: 'demo.doc',
        //     previewReportId: previewData.previewId
        // }

        // pptfileObj = {
        //     uri: 'https://sample-videos.com/ppt/Sample-PPT-File-500kb.ppt',
        //     fileType: 'ppt',
        //     fileName: 'demo.ppt',
        //     previewReportId: previewData.previewId
        // }

        setPreviewpdfFileObj(pdffileObj);
        setPreviewdocFileObj(docfileObj);
        setPreviewpptFileObj(pptfileObj);
        setPreviewjsonFileObj(jsonfileObj);

        if (generateModalType === 'generate') {
            navigate(`${TEMPLATE_ROUTE}/${HISTORY}#report`, {
                state: { id: previewData.reportHistoryId }
            });
        }
    };

    const [intervalId, setIntervalId] =
        useState<ReturnType<typeof setInterval>>();

    // To-do after download api will be available
    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval>;
        if (shouldIntervalStart) {
            intervalId = setInterval(() => {
                getRunningReport(previewReportIdAndName.previewId)
                    .then((res: AxiosResponse<GenerateReportResponse>) => {
                        if (
                            res.data.status === 'FAILED' ||
                            res.data.status === 'COMPLETED'
                        ) {
                            clearInterval(intervalId);
                            showToast(
                                res.data.status === 'FAILED'
                                    ? REPORT_GENERATION_ERROR.replace(
                                          '%s',
                                          previewReportIdAndName.previewName
                                      )
                                    : SUCCESSFUL_REPORT_GENERATED.replace(
                                          '%s',
                                          previewReportIdAndName.previewName
                                      ),
                                res.data.status === 'FAILED'
                                    ? TOAST_TYPE.ERROR
                                    : TOAST_TYPE.SUCCESS,
                                TOAST_ID
                            );
                            setShouldIntervalStart(false);
                            setIsModalLoading(false);

                            if (
                                isPreviewModalOpen &&
                                res.data.status === 'FAILED'
                            ) {
                                setIsPreviewModalOpen(prev => !prev);
                            }
                        }
                        if (res.data.status === 'COMPLETED') {
                            setPreviewFiles([
                                // @ts-ignore
                                previewpdfFileObj,
                                // @ts-ignore
                                previewdocFileObj,
                                // @ts-ignore
                                previewpptFileObj,
                                // @ts-ignore
                                previewjsonFileObj
                            ]);
                        }
                    })
                    .catch(err => {
                        displayErrorMessage(err);
                        setIsModalLoading(false);
                    })
                    .finally(() => {
                        // setTableLoading(false);
                    });
            }, 3000);
            setIntervalId(intervalId);
            // setShouldIntervalStart(false);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [shouldIntervalStart]);

    const [selectedRadioButton, setSelectedRadioButton] =
        useState('new_template');

    const onRadioButtonChange = (e: any) => {
        setSelectedRadioButton(e.target.value);
    };

    const isReportConfigurationError =
        reportFieldError.titleError ||
        Object.keys(reportElementError).length !== 0;

    const [isGenerateReport, setIsGenerateReport] = useState<boolean>(false);

    const [generatedReportId, setGeneratedReportId] = useState<string>('');

    return (
        <>
            <div>
                <Header title={REPORT_TEMPLATE}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            className="header-add-button"
                            color="primary"
                            disabled={isReportConfigurationError}
                            onClick={() => onPreviewClick()}
                            style={{
                                width: '125px',
                                paddingLeft: '15px'
                            }}
                            variant="contained"
                        >
                            {PREVIEW}
                        </Button>
                        <Button
                            className="header-add-button"
                            color="primary"
                            disabled={isReportConfigurationError}
                            onClick={() => {
                                if (
                                    !reportFieldError.titleError &&
                                    !reportTitleAndName.title
                                ) {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        titleError: true,
                                        titleHelperText:
                                            'This field is required'
                                    }));
                                } else if (
                                    !reportFieldError.titleError &&
                                    Object.keys(reportElementError).length === 0
                                ) {
                                    if (!isFormDirty && generatedReportId) {
                                        setIsGenerateModalOpen(true);
                                        setIsGenerateReport(true);
                                    } else {
                                        setIsWarningModalOpen(true);
                                    }
                                    setGenerateModalType('generate');
                                }
                            }}
                            style={{
                                width: '125px',
                                paddingLeft: '15px',
                                height: '32px'
                            }}
                            variant="outlined"
                        >
                            {GENERATE}
                        </Button>

                        <IconButton
                            className="header-add-button"
                            onClick={handleClickMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorElMenu}
                            id="button-dropdown"
                            onClose={handleCloseMenu}
                            open={openMenu}
                            PaperProps={
                                {
                                    component: StyledMenuPaper,
                                    sx: {
                                        zIndex: 10002,
                                        maxHeight: '268px'
                                    }
                                } as Partial<PaperProps<'div', {}>> | undefined
                            }
                        >
                            {menuItems.map(menu => (
                                <MenuItem
                                    key={menu.value}
                                    disabled={isReportConfigurationError}
                                    onClick={() => handleMenuItems(menu.value)}
                                    value={menu.value}
                                >
                                    {menu.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    {/* </ProtectedAction> */}
                </Header>
                {/* @ts-ignore */}
                <a id="downloadAnchorElem" style={{ display: 'none' }}>
                    {DOWNLOAD_JSON}
                </a>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <PageContainer>
                    <div
                        style={{
                            minWidth: leftPanelExpanded ? '370px' : '30px',
                            maxWidth: leftPanelExpanded ? '370px' : '30px',
                            maxHeight: '100%',
                            transition: 'all 0.4s',
                            height: '100%',
                            borderTop: borderString,
                            // position: 'sticky',
                            top: '0'
                        }}
                    >
                        <StyledHeader
                            style={{
                                borderBottom: borderString,
                                height: '45px'
                            }}
                        >
                            {leftPanelExpanded && (
                                <StyledTypography
                                    style={{
                                        fontFamily: 'Proxima Nova Bld',
                                        marginLeft: '25px',
                                        paddingTop: '15px'
                                    }}
                                >
                                    {LIBRARY}
                                </StyledTypography>
                            )}
                            <IconButton
                                onClick={() =>
                                    setleftPanelExpanded(
                                        prevValue => !prevValue
                                    )
                                }
                                sx={{
                                    marginRight: leftPanelExpanded
                                        ? '9px'
                                        : '0',
                                    marginLeft: leftPanelExpanded ? '0' : '9px'
                                }}
                            >
                                <ArrowDown
                                    className={
                                        leftPanelExpanded ? 'open' : 'close'
                                    }
                                />
                            </IconButton>
                        </StyledHeader>

                        {leftPanelExpanded && (
                            <Accordion
                                // @ts-ignore
                                accordions={accordions}
                                setExpandedPanel={setExpandedPanel}
                                sx={{
                                    '.MuiAccordionDetails-root': {
                                        padding: '8px 8px 10px'
                                    },
                                    minWidth: '370px'
                                }}
                            />
                        )}
                    </div>
                    <Divider
                        flexItem
                        orientation="vertical"
                        sx={{
                            position: 'sticky',
                            top: 0
                        }}
                    />
                    <MiddleContainer>
                        <div
                            style={{
                                maxWidth: '178px',
                                minWidth: '170px',
                                borderTop: borderString
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: borderString,
                                    height: '45px',
                                    textAlign: 'center'
                                }}
                            >
                                <StyledTypography
                                    style={{
                                        fontFamily: 'Proxima Nova Bld',
                                        paddingTop: '15px'
                                    }}
                                >
                                    {REPORT_PREVIEW}
                                </StyledTypography>
                            </div>
                            <Content>
                                {Object.keys(reportFieldsData).map(list => (
                                    <Droppable
                                        key="reportlet-preview"
                                        droppableId="reportlet-preview"
                                        // key={list}
                                        // droppableId={list}
                                        // type="report-thumbnail"
                                        type="report-items"
                                    >
                                        {provided => (
                                            <MiddlePaneContainer
                                                ref={provided.innerRef}

                                                // isDraggingOver={
                                                //     snapshot.isDraggingOver
                                                // }
                                            >
                                                {/* @ts-ignore */}
                                                {reportFieldsData[list].map(
                                                    // @ts-ignore
                                                    (item, index) => (
                                                        <Draggable
                                                            key={
                                                                item.previewDragAndDropId
                                                            }
                                                            draggableId={
                                                                item.previewDragAndDropId
                                                            }
                                                            index={index}
                                                        >
                                                            {dragProvided => (
                                                                <DragableDiv
                                                                    ref={
                                                                        dragProvided.innerRef
                                                                    }
                                                                    // isDragging={
                                                                    //     snapshot.isDragging
                                                                    // }
                                                                    {...dragProvided.draggableProps}
                                                                    {...dragProvided.dragHandleProps}
                                                                    style={
                                                                        dragProvided
                                                                            .draggableProps
                                                                            .style
                                                                    }
                                                                    id={
                                                                        item.previewDragAndDropId
                                                                    }
                                                                    onClick={() => {
                                                                        setSelectedDashboardIds(
                                                                            (
                                                                                previous: any
                                                                            ) => ({
                                                                                previewDragAndDropId:
                                                                                    item.previewDragAndDropId,
                                                                                dragAndDropId:
                                                                                    item.dragAndDropId
                                                                            })
                                                                        );
                                                                        document
                                                                            .getElementById(
                                                                                item.dragAndDropId
                                                                            )
                                                                            ?.scrollIntoView(
                                                                                {
                                                                                    behavior:
                                                                                        'smooth'
                                                                                }
                                                                            );
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            marginBottom:
                                                                                '15px'
                                                                        }}
                                                                    >
                                                                        {renderSymbol(
                                                                            item
                                                                        )}
                                                                    </div>
                                                                </DragableDiv>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                            </MiddlePaneContainer>
                                        )}
                                    </Droppable>
                                ))}
                            </Content>
                        </div>
                    </MiddleContainer>
                    <RightSideContainer>
                        <Divider
                            flexItem
                            orientation="vertical"
                            sx={{
                                position: 'sticky',
                                top: 0
                            }}
                        />
                        <div
                            style={{
                                width: '100%',
                                borderTop: borderString
                                // borderLeft: borderString
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: borderString,
                                    height: '45px',
                                    paddingTop: '15px',
                                    paddingLeft: '15px'
                                }}
                            >
                                <StyledTypography
                                    style={{ fontFamily: 'Proxima Nova Bld' }}
                                >
                                    {REPORT_CONFIGURATION}
                                </StyledTypography>
                            </div>
                            <RightSideFieldsContainer>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        paddingLeft: '20px',
                                        paddingTop: '10px'
                                    }}
                                >
                                    <StyledField>
                                        <FormLabel htmlFor="modal-field-input-report-title">
                                            {REPORT_TITLE}
                                        </FormLabel>
                                        <TextBox
                                            autoComplete="off"
                                            error={reportFieldError.titleError}
                                            fullWidth
                                            helperText={
                                                reportFieldError.titleHelperText
                                            }
                                            hiddenLabel
                                            id="modal-field-input-report-title"
                                            InputProps={{
                                                endAdornment:
                                                    reportTitleAndName.title && (
                                                        <InputAdornment
                                                            onClick={() => {
                                                                setReportTitleAndName(
                                                                    previous => ({
                                                                        ...previous,
                                                                        title: ''
                                                                    })
                                                                );
                                                                setReportFieldError(
                                                                    previous => ({
                                                                        ...previous,
                                                                        titleError:
                                                                            true,
                                                                        titleHelperText:
                                                                            'This field is required'
                                                                    })
                                                                );
                                                            }}
                                                            position="end"
                                                            variant="outlined"
                                                        >
                                                            <IoIosClose />
                                                        </InputAdornment>
                                                    )
                                            }}
                                            onChange={e =>
                                                handleTextChange(
                                                    e,
                                                    'reportTitle'
                                                )
                                            }
                                            size="small"
                                            style={{ width: 300 }}
                                            value={
                                                reportTitleAndName.title as string
                                            }
                                            variant="outlined"
                                        />
                                    </StyledField>

                                    <StyledField style={{ marginLeft: '20px' }}>
                                        <FormLabel
                                            htmlFor=""
                                            style={{ marginLeft: '10px' }}
                                        >
                                            {LOGO}
                                        </FormLabel>
                                        <div
                                            style={{
                                                display: 'flex'
                                            }}
                                        >
                                            <IconButton
                                                className="Icon-Hover-Effect"
                                                style={{ marginTop: '-2px' }}
                                            >
                                                <Button
                                                    className="control svg-symbol-button transition theme-5"
                                                    color="primary"
                                                    component="label"
                                                    startIcon={<Upload />}
                                                    // @ts-ignore
                                                    variant="text"
                                                >
                                                    {UPLOAD_FILE}
                                                    <VisuallyHiddenInput
                                                        accept=".png,.jpg,.jpeg"
                                                        onChange={e =>
                                                            handleTextChange(
                                                                e,
                                                                'icon'
                                                            )
                                                        }
                                                        type="file"
                                                    />
                                                </Button>
                                            </IconButton>
                                            <div
                                                style={{
                                                    paddingLeft: '20px',
                                                    fontFamily:
                                                        'Proxima Nova W08 Smbd',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {(reportId ||
                                                    (state && state.data)) &&
                                                logoPath ? (
                                                    <>
                                                        <a
                                                            href={`${
                                                                axiosConfig.baseURL
                                                            }/reports/logo/download?name=${encodeURIComponent(
                                                                logoPath
                                                            )}`}
                                                            style={{
                                                                color:
                                                                    theme
                                                                        .palette
                                                                        .mode ===
                                                                    'dark'
                                                                        ? 'white'
                                                                        : 'black',
                                                                textDecoration:
                                                                    'none',
                                                                maxWidth:
                                                                    '200px',
                                                                overflow:
                                                                    'hidden',
                                                                wordBreak:
                                                                    'break-word',
                                                                textOverflow:
                                                                    'ellipsis',
                                                                whiteSpace:
                                                                    'nowrap'
                                                            }}
                                                        >
                                                            {logoPath}
                                                        </a>
                                                        <IconButton
                                                            className="Icon-Hover-Effect"
                                                            onClick={() => {
                                                                setIcon([]);
                                                                setLogoPath('');
                                                            }}
                                                        >
                                                            <CrossIcon />
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    // eslint-disable-next-line
                                                    <>
                                                        {icon?.length > 0 && (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        color:
                                                                            theme
                                                                                .palette
                                                                                .mode ===
                                                                            'dark'
                                                                                ? 'white'
                                                                                : 'black',
                                                                        maxWidth:
                                                                            '200px',
                                                                        overflow:
                                                                            'hidden',
                                                                        wordBreak:
                                                                            'break-word',
                                                                        textOverflow:
                                                                            'ellipsis',
                                                                        whiteSpace:
                                                                            'nowrap'
                                                                    }}
                                                                >
                                                                    {
                                                                        icon[0]
                                                                            ?.name
                                                                    }
                                                                </div>
                                                                <IconButton
                                                                    className="Icon-Hover-Effect"
                                                                    onClick={() => {
                                                                        setIcon(
                                                                            []
                                                                        );
                                                                        setLogoPath(
                                                                            ''
                                                                        );
                                                                    }}
                                                                >
                                                                    <CrossIcon />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </StyledField>
                                </div>
                                {Object.keys(reportFieldsData).map(list => (
                                    <Droppable
                                        key={list}
                                        droppableId={list}
                                        type="report-items"
                                    >
                                        {provided => (
                                            <>
                                                {reportFieldsData.reportlet_data
                                                    .length > 0 ? (
                                                    <RightPaneContainer
                                                        ref={provided.innerRef}
                                                    >
                                                        {/* @ts-ignore */}
                                                        {reportFieldsData[
                                                            list
                                                        ].map(
                                                            // @ts-ignore
                                                            (item, index) => (
                                                                <Draggable
                                                                    key={
                                                                        item.dragAndDropId
                                                                    }
                                                                    draggableId={
                                                                        item.dragAndDropId
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                >
                                                                    {dragProvided => (
                                                                        <DragableDiv
                                                                            id={
                                                                                item.dragAndDropId
                                                                            }
                                                                            ref={
                                                                                dragProvided.innerRef
                                                                            }
                                                                            {...dragProvided.draggableProps}
                                                                            // @ts-ignore
                                                                            // isDragging={
                                                                            //     snapshot.isDragging
                                                                            // }
                                                                            style={
                                                                                dragProvided
                                                                                    .draggableProps
                                                                                    .style
                                                                            }
                                                                            {...dragProvided.dragHandleProps}
                                                                            onClick={() => {
                                                                                setSelectedDashboardIds(
                                                                                    (
                                                                                        previous: any
                                                                                    ) => ({
                                                                                        dragAndDropId:
                                                                                            item.dragAndDropId,
                                                                                        previewDragAndDropId:
                                                                                            item.previewDragAndDropId
                                                                                    })
                                                                                );
                                                                                document
                                                                                    .getElementById(
                                                                                        item.previewDragAndDropId
                                                                                    )
                                                                                    ?.scrollIntoView(
                                                                                        {
                                                                                            behavior:
                                                                                                'smooth'
                                                                                        }
                                                                                    );
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    padding:
                                                                                        '15px',
                                                                                    paddingBottom:
                                                                                        '15px',
                                                                                    paddingTop:
                                                                                        '10px'
                                                                                }}
                                                                            >
                                                                                {renderItem(
                                                                                    item
                                                                                )}
                                                                            </div>
                                                                        </DragableDiv>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        )}

                                                        {provided.placeholder}
                                                    </RightPaneContainer>
                                                ) : (
                                                    <RightPaneContainerTwo
                                                        ref={provided.innerRef}
                                                    >
                                                        {provided.placeholder}
                                                    </RightPaneContainerTwo>
                                                )}
                                            </>
                                        )}
                                    </Droppable>
                                ))}
                            </RightSideFieldsContainer>
                        </div>
                    </RightSideContainer>
                </PageContainer>
            </DragDropContext>
            <div />
            {isGenerateModalOpen && (
                <GenerateReport
                    onClickCancel={() => {
                        setIsGenerateModalOpen(false);
                        setIsGenerateReport(false);
                    }}
                    onSubmitSuccess={previewData =>
                        onGenerateReportSubmitSuccess(previewData)
                    }
                    reportFieldsData={reportFieldsData}
                    // @ts-ignore
                    reportId={generatedReportId || reportId}
                    reportTemplateName={reportTitleAndName.name}
                    reportTitleAndName={reportTitleAndName}
                    setIsLoading={setIsLoading}
                    submitBtnLabel={
                        generateModalType === 'preview'
                            ? 'Preview Report'
                            : 'Generate Report'
                    }
                    title={
                        generateModalType === 'preview'
                            ? 'Preview Report'
                            : 'Generate Report'
                    }
                    type={generateModalType}
                    icon={icon}
                    logoPath={logoPath}
                />
            )}
            {isPreviewModalOpen && (
                <ReportPreview
                    isModalLoading={isModalLoading}
                    onClickCancel={() => {
                        setIsPreviewModalOpen(false);
                        clearInterval(intervalId);
                        setPreviewFiles([]);
                        setShouldIntervalStart(false);
                    }}
                    setIsLoading={setIsLoading}
                    setIsModalLoading={setIsModalLoading}
                    previewfiles={previewFiles}
                    previewFileType={previewFileType}
                    onSubmitSuccess={type => onReportPreviewSubmit(type)}
                />
            )}

            <StyledModal
                isModalOpen={isSaveModalOpen}
                isDisabledSubmitBtn={
                    (selectedRadioButton === 'new_template' &&
                        (reportFieldError.templateNameError ||
                            reportTitleAndName.name.length === 0)) ||
                    (selectedRadioButton === 'existing_template' &&
                        selectedTemplate === '')
                }
                title="Save As"
                submitBtnLabel="Save"
                onSubmit={onSaveModalSubmit}
                onCancel={() => {
                    setIsSaveModalOpen(false);
                    setIsGenerateReport(false);
                }}
                className="medium-width"
            >
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={selectedRadioButton}
                    name="radio-buttons-group"
                    onChange={onRadioButtonChange}
                    row
                >
                    {reportId && (
                        <FormControlLabel
                            value="save"
                            control={<Radio />}
                            label="Save"
                        />
                    )}
                    <FormControlLabel
                        value="new_template"
                        control={<Radio />}
                        label="New Template"
                    />
                    <FormControlLabel
                        value="existing_template"
                        control={<Radio />}
                        label="Existing Template"
                    />
                </RadioGroup>
                {(selectedRadioButton === 'new_template' ||
                    selectedRadioButton === 'save') && (
                    <div style={{ marginTop: '20px' }}>
                        <ModalField
                            key={1}
                            error={reportFieldError.templateNameError}
                            helperText={reportFieldError.templateHelperText}
                            index={1}
                            labelName="Template Name*"
                            onChange={e => {
                                if (e.length <= 100) {
                                    setReportTitleAndName(previous => ({
                                        ...previous,
                                        name: e
                                    }));
                                }

                                if (e.length === 0) {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        templateNameError: true,
                                        templateHelperText:
                                            'This field is required'
                                    }));
                                } else if (e.length > 100) {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        templateNameError: true,
                                        templateHelperText:
                                            'Max 100 characters are allowed in template name'
                                    }));
                                } else if (e.length <= 100) {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        templateNameError: false,
                                        templateHelperText: ''
                                    }));
                                } else {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        templateNameError: false,
                                        templateHelperText: ''
                                    }));
                                }
                            }}
                            type={FIELD_TYPE_MAP.TEXT}
                            value={reportTitleAndName.name}
                        />
                        <TagsWithInputBox
                            className="content"
                            componentsProps={{
                                popper: {
                                    sx: { zIndex: 10002 }
                                }
                            }}
                            data-testid="tags-autocomplete"
                            defaultValue={[...reportTitleAndName.tags]}
                            id="template-tags-input"
                            onInputChange={(_, value) =>
                                value.trim().length &&
                                onTagsSearchInputChangeHandler(value)
                            }
                            onTagsChangeEmit={(value: string[]) => {
                                setReportTitleAndName(previous => ({
                                    ...previous,
                                    tags: value
                                }));
                                setTags([]);
                            }}
                            options={tags}
                            placeholder="Search tags..."
                            readOnlyOptions={['AMP Managed']}
                        />
                        <Typography
                            className="content"
                            sx={{ color: constants.COLOR_1 }}
                            variant="h6"
                        >
                            {CREATE_TAG_INFO}
                        </Typography>
                    </div>
                )}
                {selectedRadioButton === 'existing_template' && (
                    <div style={{ marginTop: '20px' }}>
                        <StyledTypography
                            fontWeight={600}
                            marginBottom="8px"
                            variant="h5"
                        >
                            {SELECT_BUILT_IN_TEMPLATE}
                        </StyledTypography>
                        {reportList.length > 0 && (
                            // @ts-ignore
                            <>
                                <ModalField
                                    key={1}
                                    error={
                                        reportFieldError.existingTemplateError
                                    }
                                    helperText={
                                        reportFieldError.existingTemplateHelperText
                                    }
                                    index={1}
                                    items={reportList.map(
                                        report => report.label
                                    )}
                                    onChange={e => {
                                        setSelectedTemplate(e);
                                    }}
                                    placeHolder="Select"
                                    type={FIELD_TYPE_MAP.DROPDOWN}
                                />
                                {reportFieldError.existingTemplateError && (
                                    <FormHelperText sx={{ color: '#e31c3d' }}>
                                        {ROLE_NAME_ERROR_EMPTY}
                                    </FormHelperText>
                                )}
                            </>
                        )}
                    </div>
                )}
            </StyledModal>

            <StyledModal
                isModalOpen={isDownloadJSONModalOpen}
                isDisabledSubmitBtn={
                    reportFieldError.templateNameError ||
                    reportTitleAndName.name.length === 0
                }
                title={DOWNLOAD_JSON}
                submitBtnLabel={DOWNLOAD_JSON}
                onSubmit={() => {
                    downloadJSON();
                    setIsDownloadJSONModalOpen(false);
                }}
                onCancel={() => {
                    setIsDownloadJSONModalOpen(false);
                }}
                className="medium-width"
            >
                <div style={{ marginTop: '20px' }}>
                    <ModalField
                        key={1}
                        error={reportFieldError.templateNameError}
                        helperText={reportFieldError.templateHelperText}
                        index={1}
                        labelName="Template Name*"
                        onChange={e => {
                            if (e.length <= 100) {
                                setReportTitleAndName(previous => ({
                                    ...previous,
                                    name: e
                                }));
                            }

                            if (e.length === 0) {
                                setReportFieldError(previous => ({
                                    ...previous,
                                    templateNameError: true,
                                    templateHelperText: 'This field is required'
                                }));
                            } else if (e.length > 100) {
                                setReportFieldError(previous => ({
                                    ...previous,
                                    templateNameError: true,
                                    templateHelperText:
                                        'Max 100 characters are allowed in template name'
                                }));
                            } else if (e.length <= 100) {
                                setReportFieldError(previous => ({
                                    ...previous,
                                    templateNameError: false,
                                    templateHelperText: ''
                                }));
                            } else {
                                setReportFieldError(previous => ({
                                    ...previous,
                                    templateNameError: false,
                                    templateHelperText: ''
                                }));
                            }
                        }}
                        type={FIELD_TYPE_MAP.TEXT}
                        value={reportTitleAndName.name}
                    />
                </div>
            </StyledModal>

            <Modal
                displayBtn="all"
                isModalOpen={isWarningModalOpen}
                onCancel={() => setIsWarningModalOpen(false)}
                onSubmit={() => {
                    setIsWarningModalOpen(false);
                    setIsSaveModalOpen(true);
                    setIsGenerateReport(true);
                }}
                submitBtnLabel="Ok"
                title="Warning"
            >
                <WarningModalContainer text="Please save the unsaved changes to generate the report." />
            </Modal>
        </>
    );
};

export const CreateReport = IsLoadingHOC(CreateReportComponent);
