import {
    analytics,
    settings,
    policies,
    tenants,
    logo_amp
} from 'src/assets/images';
import {
    AGGREGATED_VIEW,
    AGGREGATED_VIEW_SECTION_TITLE,
    ANALYTICS_ITEM_NAME,
    AUDIT_LOG_HEADER,
    CUSTOM_PROPERTIES_TITLE,
    DASHBOARDS,
    NOTIFICATIONS_LOGGING_HEADER,
    POLICIES,
    PUSH_HISTORY_ITEM_NAME,
    REPORTS_ITEM_NAME,
    ROLE_MANAGEMENT,
    SETTINGS,
    LOGIN_MANAGEMENT,
    TEMPLATE,
    TENANTS,
    USER_MANAGEMENT,
    USERS_MANAGEMENT_HEADER
} from 'src/constants/LabelText';
import { NavBarType } from 'src/types/CommonTypes';

import { Privileges, Resources } from './CommonConstants';

export const LOGIN = '/login';
export const SET_PASSWORD = '/set-password';
export const HOME = '/';
export const MY_PROFILE = '/my-profile';
export const SETTINGS_ROUTE = '/settings';
export const ANALYTICS = '/analytics';
export const ROLE_MANAGEMENT_ROUTE = 'role-management';
export const USER_MANAGEMENT_ROUTE = 'user-management';
export const DASHBOARDS_ROUTE = 'dashboards';
export const SSO_AND_SAML_ROUTE = 'saml';
export const TENANT_MANAGEMENT = '/tenants';
export const AUDIT_LOG = 'audit-log';
export const TEMPLATE_ROUTE = '/template';
export const REPORT_ROUTE = 'reports';
export const CREATE_REPORT = 'create-reports';
export const EDIT_REPORT = 'edit-report';
export const POLICY = 'policy';
export const PUSH_HISTORY = 'history/push';
export const RUN_HISTORY = 'history/run';
export const POLICY_HISTORY = 'history#policy';
export const REPORT_HISTORY = 'history#report';
export const CUSTOM_PROPERTIES = 'overview-thresholds';
export const REPORTS = 'reports';
export const HISTORY = 'history';

export const sideNavbarProps: NavBarType = {
    logoSVG: logo_amp,
    logoClickRedirect: HOME,
    items: [
        {
            itemName: ANALYTICS_ITEM_NAME,
            itemSVG: analytics,
            subMenuItems: [
                {
                    itemContent: [
                        {
                            itemName: AGGREGATED_VIEW,
                            routePath: HOME,
                            isSSOConfigRequired: true
                        },
                        {
                            itemName: DASHBOARDS,
                            routePath: `${ANALYTICS}/${DASHBOARDS_ROUTE}`,
                            isSSOConfigRequired: true
                        }
                    ]
                }
            ]
        },
        {
            itemName: TENANTS,
            routePath: TENANT_MANAGEMENT,
            itemSVG: tenants,
            isSSOConfigRequired: true,
            permissions: [
                {
                    resource: Resources.tenant,
                    requiredPermission: [Privileges.read]
                }
            ]
        },
        {
            itemName: TEMPLATE,
            itemSVG: policies,
            subMenuItems: [
                {
                    itemContent: [
                        {
                            itemName: POLICIES,
                            routePath: `${TEMPLATE_ROUTE}/${POLICY}`,
                            isSSOConfigRequired: true,
                            permissions: [
                                {
                                    resource: Resources.policy,
                                    requiredPermission: [Privileges.read]
                                }]
                        },
                        {
                            itemName: REPORTS_ITEM_NAME,
                            routePath: `${TEMPLATE_ROUTE}/${REPORTS}`,
                            isSSOConfigRequired: true,
                            permissions: [
                                {
                                    resource: Resources.reports,
                                    requiredPermission: [Privileges.read]
                                }
                            ]
                        },
                        {
                            itemName: PUSH_HISTORY_ITEM_NAME,
                            routePath: `${TEMPLATE_ROUTE}/${HISTORY}`,
                            isSSOConfigRequired: true,
                            permissions: [
                                {
                                    resource: Resources.reports,
                                    requiredPermission: [Privileges.runreport]
                                },
                                {
                                    resource: Resources.policy,
                                    requiredPermission: [Privileges.push]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            itemName: SETTINGS,
            itemSVG: settings,
            subMenuItems: [
                {
                    itemTitle: USER_MANAGEMENT,
                    itemContent: [
                        {
                            itemName: USERS_MANAGEMENT_HEADER,
                            routePath: `${SETTINGS_ROUTE}/${USER_MANAGEMENT_ROUTE}`,
                            isSSOConfigRequired: true,
                            permissions: [
                                {
                                    resource: Resources.users,
                                    requiredPermission: [Privileges.read]
                                }]
                        },
                        {
                            itemName: ROLE_MANAGEMENT,
                            routePath: `${SETTINGS_ROUTE}/${ROLE_MANAGEMENT_ROUTE}`,
                            isSSOConfigRequired: true,
                            permissions: [
                                {
                                    resource: Resources.roles,
                                    requiredPermission: [Privileges.read]
                                }]
                        },
                        {
                            itemName: LOGIN_MANAGEMENT,
                            routePath: `${SETTINGS_ROUTE}/${SSO_AND_SAML_ROUTE}`,
                            isSSOConfigRequired: false,
                            permissions: [
                                {
                                    resource: Resources.settings,
                                    requiredPermission: [Privileges.saml]
                                }]
                        }
                    ]
                },
                {
                    itemTitle: NOTIFICATIONS_LOGGING_HEADER,
                    itemContent: [
                        {
                            itemName: AUDIT_LOG_HEADER,
                            routePath: `${SETTINGS_ROUTE}/${AUDIT_LOG}`,
                            isSSOConfigRequired: true,
                            permissions: [
                                {
                                    resource: Resources.settings,
                                    requiredPermission: [Privileges.auditLog]
                                }]
                        }
                    ]
                },
                {
                    itemTitle: CUSTOM_PROPERTIES_TITLE,
                    itemContent: [
                        {
                            itemName: AGGREGATED_VIEW_SECTION_TITLE,
                            routePath: `${SETTINGS_ROUTE}/${CUSTOM_PROPERTIES}`,
                            isSSOConfigRequired: true,
                            permissions: [
                                {
                                    resource: Resources.settings,
                                    requiredPermission: [Privileges.configurationUpdate]
                                }]
                        }
                    ]
                }
            ]
        }
    ]
};

export const defaultRouteConstants: { [key: string]: string } = {
    [ANALYTICS]: HOME,
    [TEMPLATE_ROUTE]: `${TEMPLATE_ROUTE}/${POLICY}`,
    [SETTINGS_ROUTE]: `${SETTINGS_ROUTE}/${USER_MANAGEMENT_ROUTE}`
} as const;
